import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { DashboardExportService } from '../dashboard-export/dashboard-export-service/dashboard-export.service';
import { DashletEventsService } from '../dashlet/dashlet-events/dashlet-events.service';

import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TDashletEventsRequestData } from '../dashlet/dashlet-events/dashlet-events.consts';
import { checkAnyDashletSelected } from './check-any-dashlet-selected';
import { TExportDashlet } from './dashboard-export-dashlet.model';
import { DASHLET_NAMES } from './dashlet-names.const';
import { generateAdvancedDashletList } from './generate-advanced-dashlet-list';
import { generateDashletList } from './generate-dashlet-list';
import { generateSimpleDashletList } from './generate-simple-dashlet-list';

@Component({
  selector: 'pp-dashboard-export-modal',
  templateUrl: './dashboard-export-modal.component.html',
  styleUrls: ['./dashboard-export-modal.component.scss'],
})
export class DashboardExportModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('exportZone') exportZone: ElementRef;

  modal: Modal;
  processing = false;
  preferences: TPreferences;
  anyDashletSelected = true;

  simpleDashlets: TExportDashlet[] = [];
  advancedDashlets: TExportDashlet[] = [];

  selectedFormat = 'a4';

  workspaces: TWorkspacesById;
  accounts: TAccount[];
  dashletData: TDashletEventsRequestData[];

  exportFormats = [
    { label: 'A4', value: 'a4' },
    { label: 'Letter', value: 'letter' },
  ];

  dashletNames = DASHLET_NAMES;

  constructor(
    private modalService: ModalService,
    private preferencesService: PreferencesService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private dashletEventsService: DashletEventsService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private dashboardExportService: DashboardExportService,
  ) {
    this.workspaces = this.workspaceService.getWorkspaces();
    this.accounts = this.accountService.getAccounts();
  }

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    this.preferences = this.preferencesService.getPreferences();
    this.dashletData = this.dashletEventsService.getRequestQueue();

    this.initDashletList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  export(): void {
    const prompt = this.translationPipe.transform('prompt_export_generation');
    const dashletList = [...this.simpleDashlets, ...this.advancedDashlets];

    this.processing = true;

    this.promptService.showSuccess(prompt);

    this.dashboardExportService
      .export(dashletList, this.dashletData, this.selectedFormat)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.hideModal();
        }),
      )
      .subscribe();
  }

  initDashletList(): void {
    const dashletsList = generateDashletList(this.preferences, this.accounts, this.workspaces);

    this.simpleDashlets = generateSimpleDashletList(dashletsList);
    this.advancedDashlets = generateAdvancedDashletList(dashletsList);
    this.anyDashletSelected = checkAnyDashletSelected(this.simpleDashlets, this.advancedDashlets);
  }

  toggleExportDashlet(dashlet: TExportDashlet): void {
    dashlet.selected = !dashlet.selected;

    this.anyDashletSelected = checkAnyDashletSelected(this.simpleDashlets, this.advancedDashlets);
  }

  toggleFormat(format: { label: string; value: string }): void {
    this.selectedFormat = format.value;
  }
}
