<table class="userManagementSites__table" [@openClose]="isOpen ? 'open' : 'closed'">
  <pp-user-management-site-row
    *ngFor="let share of ppUser.shares; let index = index; let last = last"
    [ppCustomFields]="ppCustomFields"
    [ppIndex]="index"
    [ppShare]="share"
    [ppLast]="last"
    (ppNavigate)="navigateToSite($event)"
  ></pp-user-management-site-row>
</table>
