import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, catchError, finalize, map, takeUntil, tap, throwError } from 'rxjs';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { AccountService } from '../../account/account-service/account.service';
import { AddUsersIssuesService } from '../../account/account-settings/add-users-modal/add-users-issues.service';
import { UsersService } from '../../users/users.service';
import { FleetService } from '../fleet-service/fleet.service';
import { EFleetShareOption, TFleetShare } from '../fleet-sharing/fleet-sharing.consts';
import { FleetSharingService } from '../fleet-sharing/fleet-sharing.service';
import { TFleet } from '../fleet.consts';
import { TFleetSharingModalComponent } from './fleet-sharing-modal-component.consts';
import { FleetSharingModalService } from './fleet-sharing-modal.service';

@Component({
  selector: 'pp-fleet-sharing-modal',
  templateUrl: './fleet-sharing-modal.component.html',
  styleUrls: ['./fleet-sharing-modal.component.scss'],
})
export class FleetSharingModalComponent implements OnInit, OnDestroy {
  fleet: TFleet;
  modalHeader: string;

  emailList: string[] = [];
  errorMessages: string[] = [];
  warningMessages: string[] = [];
  selectedShares: string[] = [];
  selectableShares: string[] = [];
  fleetShares: TFleetShare[] = [];
  processing: boolean = false;
  showingError: boolean = false;
  private readonly destroy$ = new Subject<void>();
  private modal: Modal = this.modalService.getModal();

  constructor(
    private fleetService: FleetService,
    private fleetSharingService: FleetSharingService,
    private translationPipe: TranslationPipe,
    private addUsersIssuesService: AddUsersIssuesService,
    private modalService: ModalService,
    private usersService: UsersService,
    private fleetSharingModalService: FleetSharingModalService,
    private accountService: AccountService,
  ) {
    this.fleet = this.fleetService.getFleet(this.fleetService.getActiveFleetId());
    this.setModalHeader();
    this.subscribeToErrorChange();
    this.fleetSharingModalService.clearEmailList();
    this.fleetSharingModalService.setConfirmClose(false);

    this.modalService.modalDataChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const modalData: TFleetSharingModalComponent = this.modal.data;
      this.showingError = modalData.showError;
    });
  }

  ngOnInit(): void {
    this.fleetSharingService
      .getFleetShares(this.fleet.id)
      .pipe(
        map((shares) =>
          shares.filter((share) => {
            const ownerId = this.accountService.getAccount(this.fleet.accountId).accountOwnerId;

            return share.userId !== ownerId;
          }),
        ),
        tap((fleetShares) => {
          this.fleetShares = fleetShares.sort((a, b) => {
            if (
              a.fleetShareOption === EFleetShareOption.EDIT &&
              b.fleetShareOption === EFleetShareOption.READ
            ) {
              return -1;
            } else if (
              a.fleetShareOption === EFleetShareOption.READ &&
              b.fleetShareOption === EFleetShareOption.EDIT
            ) {
              return 1;
            } else {
              const userA = this.usersService.getUser(a.userId);
              const userB = this.usersService.getUser(b.userId);
              return userA?.email.localeCompare(userB?.email);
            }
          });
        }),
        catchError((error) => {
          return throwError(error);
        }),
        finalize(() => {
          this.setSelectableShares();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  cancel(): void {
    this.modalService.hideModal();
  }

  selectAll(): void {
    if (this.selectedShares.length === this.selectableShares.length) {
      this.deselectAll();
    } else {
      this.selectedShares = [...this.selectableShares];
    }
  }

  deselectAll(): void {
    this.selectedShares = [];
  }

  deleteShares(): void {
    this.fleetSharingService.deleteFleetShares(this.fleet.id, this.selectedShares).subscribe(() => {
      this.fleetShares = this.fleetShares.filter(
        (share) => !this.selectedShares.includes(share.id),
      );
      this.selectedShares = [];
    });
  }

  toggleShare(fleetShare: TFleetShare): void {
    if (this.selectedShares.includes(fleetShare.id)) {
      this.selectedShares = this.selectedShares.filter((id) => id !== fleetShare.id);
    } else {
      this.selectedShares.push(fleetShare.id);
    }
  }

  deleteShare(fleetShare: TFleetShare): void {
    this.fleetSharingService
      .deleteFleetShares(fleetShare.fleetId, [fleetShare.id])
      .subscribe(() => {
        this.fleetShares = this.fleetShares.filter((share) => share.id !== fleetShare.id);
      });
  }

  inviteUsers(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    logEventInGTAG(EGoogleEventFleetManagement.FLEET_SHARE, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
      event_details: this.emailList.length.toString(),
    });

    this.fleetSharingService
      .shareFleet(this.fleet.id, this.emailList)
      .pipe(
        tap(() => {
          this.fleetSharingModalService.setConfirmClose(true);
          this.modalService.hideModal();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  updateEmails(emails: string[]): void {
    this.fleetSharingModalService.setEmailList(emails);
  }

  backFromErrorPage(): void {
    this.modalService.setData({ showError: false });
  }

  closeModalWithUnsavedChanges(): void {
    this.fleetSharingModalService.setConfirmClose(true);
    this.modalService.hideModal();
  }

  private setSelectableShares(): void {
    this.selectableShares = this.fleetShares
      .filter((share) => share.fleetShareOption !== EFleetShareOption.EDIT)
      .map((share) => share.id);
  }

  private setModalHeader(): void {
    this.modalHeader = this.translationPipe.transform('share_fleet', {
      fleetName: this.fleet.name,
      interpolation: { escapeValue: false } as any,
    });
  }

  private subscribeToErrorChange() {
    this.addUsersIssuesService.errorChange$.pipe(takeUntil(this.destroy$)).subscribe((errors) => {
      this.handleErrors();
    });
  }

  private handleErrors(): void {
    this.errorMessages = this.addUsersIssuesService.getErrorMessages();
  }
}
