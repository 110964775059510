import { isEqual } from 'lodash';
import { TExportDashlet } from '../../dashboard-export-modal/dashboard-export-dashlet.model';
import { TDashletEventsRequestData } from '../../dashlet/dashlet-events/dashlet-events.consts';

export function findDashletData(
  dashlet: TExportDashlet,
  dashletsData: TDashletEventsRequestData[],
): TDashletEventsRequestData {
  return dashletsData.find((_dashlet) => {
    const accountIdMatch =
      (!_dashlet.accountId && !dashlet.selectedRange.accountId) ||
      _dashlet.accountId === dashlet.selectedRange.accountId;

    const workspaceIdsMatch =
      (!_dashlet.workspaceIds?.length && !dashlet.selectedRange?.workspaceIds?.length) ||
      isEqual(_dashlet.workspaceIds, dashlet.selectedRange.workspaceIds);

    const typeMatch = _dashlet.type === dashlet.name;
    const periodMatch = _dashlet.period === dashlet.period;

    return accountIdMatch && workspaceIdsMatch && typeMatch && periodMatch;
  });
}
