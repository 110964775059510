import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';

import { TPoint } from 'src/app/project/modules/points/points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { DOCUMENT } from '@angular/common';
import { ClickOutsideHandler } from '@core/services';
import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { checkClearShortcut } from 'src/app/project/modules/custom-fields/utils/check-clear-shortcut';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TAutoNumericOptions } from '../../../../../../core/helpers/create-autonumeric';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-percentage',
  templateUrl: './point-fields-percentage.component.html',
  styleUrls: ['./point-fields-percentage.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsPercentageComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppField: TPointCustomField;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  private readonly destroy$ = new Subject<void>();

  @ViewChild('percentageInput', { static: false }) percentageInputElement: ElementRef;
  @ViewChild('customFieldElement', { static: false }) customFieldElement: ElementRef;

  updating = false;
  success = false;
  error = false;
  value: string;
  focused = false;
  EIconPath = EIconPath;

  autonumericOptions: TAutoNumericOptions = {
    watchExternalChanges: true,
    modifyValueOnWheel: false,
    decimalPlaces: 0,
    decimalPlacesRawValue: 0,
  };

  private clickOutsideHandler: ClickOutsideHandler;
  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 500;
  private successTimerMs = 2500;
  private removeClickListenerTimerMs = 100;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private ngZone: NgZone,
    private customTableService: CustomTableService,
  ) {}

  ngAfterViewInit() {
    this.clickOutsideHandler = new ClickOutsideHandler(
      this.customFieldElement.nativeElement,
      this.destroy$,
    );
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnChanges() {
    if (this.document.activeElement !== this.percentageInputElement?.nativeElement) {
      this.value = this.ppField?.value;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.value = null;
    this.updateField();
  }

  onKeyDown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());
  }

  updateField(): void {
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = this.value;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, fieldValue, workspaceId);
        }),
      )
      .subscribe();
  }

  onBlur(): void {
    this.focused = false;

    this.ngZone.runOutsideAngular(() => {
      timer(this.removeClickListenerTimerMs).subscribe(() => {
        this.clickOutsideHandler.disable();
      });
    });
  }

  onFocus(): void {
    this.focused = true;

    this.clickOutsideHandler.enable();
  }

  blurInput(event: Event): void {
    const target = event.target as HTMLElement;

    target.blur();
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, fieldValue, workspaceId);

      return;
    }

    this.updating = true;

    const customField: Record<string, string>[] = this.pointFieldsService.createCustomField2(
      fieldId,
      fieldValue,
      ECustomFieldType.PERCENTAGE,
    );

    this.percentageInputElement.nativeElement.blur();

    this.pointsUpdateService
      .updatePointField(_id, { customFieldsMap: customField })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_percentage_update');

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints(true);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private onClickOutside(event: MouseEvent): void {
    event.stopImmediatePropagation();

    this.percentageInputElement.nativeElement.blur();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: fieldId,
        customFieldValue: fieldValue
          ? fieldValue.replace(/,/g, '').replace(/%/g, '').trim()
          : fieldValue,
      }),
    );
  }
}
