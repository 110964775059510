import { Injectable } from '@angular/core';

import { DeviceService } from '@core/services';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { TDashletCostComparisonResponse, TDashletStackedResponse } from '../../dashboard.consts';
import { TDashletGraph } from '../../dashlet-graph.model';
import { EDashletType } from '../../dashlets-enums';
import { CreateCostComparisonGraphService } from './create-cost-comparison-graph.service';
import { CreateStackedStatusGraphService } from './create-stacked-status-graph.service';
import { createCurrentPriorityGraph } from './current-priority-graph';
import { createCurrentStatusGraph } from './current-status-graph';
import { createHistoricPriorityGraph } from './historic-priority-graph';
import { createHistoricStatusGraph } from './historic-status-graph';

export type TDashletNames = {
  account: string;
  workspace: string;
};

@Injectable({
  providedIn: 'root',
})
export class DashletService {
  isMobile = false;
  isTablet = false;

  constructor(
    private workspaceService: WorkspaceService,
    private deviceService: DeviceService,
    private accountService: AccountService,
    private createStackedStatusGraphService: CreateStackedStatusGraphService,
    private createCostComparisonGraphService: CreateCostComparisonGraphService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  createCurrentPriorityGraph(isExport: boolean): TDashletGraph {
    return createCurrentPriorityGraph(this.isMobile || this.isTablet, isExport);
  }

  createCurrentStatusGraph(isExport: boolean): TDashletGraph {
    return createCurrentStatusGraph(this.isMobile || this.isTablet, isExport);
  }

  createHistoricStatusGraph(isExport: boolean): TDashletGraph {
    return createHistoricStatusGraph(this.isMobile || this.isTablet, isExport);
  }

  createHistoricPriorityGraph(isExport: boolean): TDashletGraph {
    return createHistoricPriorityGraph(this.isMobile || this.isTablet, isExport);
  }

  createStackedStatusGraph(response: TDashletStackedResponse, isExport: boolean): TDashletGraph {
    return this.createStackedStatusGraphService.createStackedStatusGraph(response, isExport);
  }

  createCostComparisonGraph(
    response: TDashletCostComparisonResponse,
    isExport: boolean,
  ): TDashletGraph {
    return this.createCostComparisonGraphService.createCostComparisonGraph(response, isExport);
  }

  getDashletGraph(dashletName: EDashletType, isExport: boolean = false): TDashletGraph {
    switch (dashletName) {
      case EDashletType.CURRENT_STATUS:
        return this.createCurrentStatusGraph(isExport);
      case EDashletType.CURRENT_PRIORITY:
        return this.createCurrentPriorityGraph(isExport);
      case EDashletType.OVER_TIME_STATUS:
        return this.createHistoricStatusGraph(isExport);
      case EDashletType.OVER_TIME_PRIORITY:
        return this.createHistoricPriorityGraph(isExport);
    }
  }
}
