import { Component } from '@angular/core';
import { tap } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { NewDashletModalComponent } from 'src/app/project/modules/dashboard/new-dashlet-modal/new-dashlet-modal.component';
import { TNewDashletModalData } from 'src/app/project/modules/dashboard/new-dashlet-modal/new-dashlet-modal.consts';
import { AssetService } from '../../../../asset-service/asset.service';
import { FleetManagementRoutesService } from '../../../../fleet-management-routes.service';
import { TAssetCardOptionsDropdownData } from './asset-card-options-dropdown.consts';

@Component({
  selector: 'pp-asset-card-options-dropdown',
  templateUrl: './asset-card-options-dropdown.component.html',
  styleUrls: ['./asset-card-options-dropdown.component.scss'],
})
export class AssetCardOptionsDropdownComponent {
  dropdown = this.dropdownService.getDropdown();
  canAddDashlets: boolean;

  private fleetId: string;
  private assetId: string;

  constructor(
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private dropdownService: DropdownService,
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
    private assetService: AssetService,
    private promptService: PromptService,
  ) {
    const dropdownData: TAssetCardOptionsDropdownData = this.dropdown.data;

    this.fleetId = dropdownData.fleetId;
    this.assetId = dropdownData.assetId;
    this.canAddDashlets = this.assetService.getAsset(this.assetId).sites.length > 0;
  }

  addDashlet(): void {
    this.modalService.setData<TNewDashletModalData>({
      assetId: this.assetId,
    });

    this.modalService.showModal(NewDashletModalComponent);
    this.dropdownService.hideDropdown();
  }

  editAsset(): void {
    this.fleetManagementRoutesService.goToEditAssetPage(this.fleetId, this.assetId);
    this.dropdownService.hideDropdown();
  }

  deleteAsset(): void {
    this.dropdownService.hideDropdown();
    const asset = this.assetService.getAsset(this.assetId);

    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('delete_asset_modal_message'),
      secondaryMessage: this.translationPipe.transform('delete_asset_modal_secondary_message'),
      heading: this.translationPipe.transform('delete_asset_modal_header', {
        name: asset.name,
      }),
      redButton: true,
      confirmText: this.translationPipe.transform('Delete'),
      boldText: true,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      callback: () => {
        this.assetService
          .deleteAsset(this.assetId)
          .pipe(
            tap(() => {
              const prompt = this.translationPipe.transform('asset_deleted');

              this.promptService.showSuccess(prompt);
            }),
          )
          .subscribe();
      },
    });
  }
}
