import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { translate } from 'src/app/project/features/translate/translate';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { AccountService } from '../../../account/account-service/account.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { EDashletType } from '../../dashlets-enums';
import { EditCostComparisonDashletModalComponent } from './edit-cost-comparison-dashlet-modal/edit-cost-comparison-dashlet-modal.component';
import { TEditCostComparisonModalData } from './edit-cost-comparison-dashlet-modal/edit-cost-comparison-modal.consts';

@Component({
  selector: 'pp-dashlet-head',
  templateUrl: './dashlet-head.component.html',
  styleUrls: ['./dashlet-head.component.scss'],
})
export class DashletHeadComponent implements OnChanges {
  @Input() ppDashlet: TDashlet;
  @Input() ppAssetId: string;
  @Input() ppCanEdit: boolean;
  @Input() ppAccountName: string;
  @Input() ppWorkspaces: string[]; // hacks around Angular change detection so it reruns when you change workspace ids
  @Output() ppToggleFlip = new EventEmitter();

  EIconPath = EIconPath;
  siteName: string;
  private sitesNames: string[];
  siteTooltip: string;
  accountName: string;
  private accountNames: string[] = [];
  accountTooltip: string;
  EDashletType = EDashletType;
  title: string;
  tooltipText: string;

  constructor(
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
  ) {}

  ngOnChanges(): void {
    this.checkSites();
    this.checkAccounts();
    this.setAccountTooltip();
    this.setSiteTooltips();
    this.setTitle();
  }

  flipDashlet(): void {
    if (this.ppDashlet.name === EDashletType.COST_COMPARISON) {
      this.modalService.setData<TEditCostComparisonModalData>({
        dashlet: this.ppDashlet,
        assetId: this.ppAssetId,
      });

      this.modalService.showModal(EditCostComparisonDashletModalComponent);

      return;
    }

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__FLIP, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_details: this.ppDashlet.name,
    });

    this.ppToggleFlip.emit();
  }

  private checkSites(): void {
    if (!this.ppWorkspaces) {
      this.sitesNames = [];
      this.siteName = this.translationPipe.transform('all_sites');

      return;
    } else {
      this.sitesNames = this.ppWorkspaces.map(
        (workspace) => this.workspaceService.findWorkspace(workspace)?.siteName || '',
      );

      if (this.sitesNames.length === 0) {
        this.siteName = this.translationPipe.transform('all_sites');
      } else if (this.sitesNames.length === 1) {
        this.siteName = this.sitesNames[0];
      } else {
        this.siteName = this.translationPipe.transform('multiple_sites');
      }
    }
  }

  private checkAccounts(): void {
    if (this.ppAccountName) {
      this.accountName = this.ppAccountName;
      this.accountNames = [this.ppAccountName];
    } else if (!this.ppWorkspaces || this.ppWorkspaces.length === 0) {
      this.accountName = this.translationPipe.transform('all_accounts');
      this.accountNames = [];
    } else {
      this.accountNames = this.ppWorkspaces.map((workspace) => {
        const accountId = this.workspaceService.findWorkspace(workspace)?.accountId;
        return this.accountService.getAccount(accountId)?.accountName || '';
      });

      if (new Set(this.accountNames).size === 1) {
        this.accountName = this.accountNames[0];
      } else {
        this.accountName = this.translationPipe.transform('multiple_accounts');
      }
    }
  }

  private setAccountTooltip(): void {
    const uniqueAccounts = Array.from(new Set(this.accountNames));

    if (uniqueAccounts.length < 2) {
      this.accountTooltip = this.accountName;
      return;
    }

    let accountsList = '';

    uniqueAccounts.forEach((account) => {
      accountsList += `<li>${account}</li>`;
    });

    const tooltip = `<div class="tooltip__fleetStatuses">
      <div>${translate('showing_accounts')}:</div>
      <ul>
        ${accountsList}
      </ul>
    </div>`;

    this.accountTooltip = tooltip;
  }

  private setSiteTooltips(): void {
    if (this.sitesNames.length < 2) {
      this.siteTooltip = this.siteName;
      return;
    }

    let sitesList = '';

    this.sitesNames.forEach((site) => {
      sitesList += `<li>${site}</li>`;
    });

    const tooltip = `<div class="tooltip__fleetStatuses">
      <div>${translate('showing_sites')}:</div>
      <ul>
        ${sitesList}
      </ul>
    </div>`;

    this.siteTooltip = tooltip;
  }

  private setTitle(): void {
    switch (this.ppDashlet.name) {
      case EDashletType.ACTIVITIES:
        this.title = this.translationPipe.transform('activity');
        this.tooltipText = '';
        break;
      case EDashletType.CURRENT_STATUS:
        this.title = this.translationPipe.transform('dashlets_live_status');
        this.tooltipText = '';
        break;
      case EDashletType.CURRENT_PRIORITY:
        this.title = this.translationPipe.transform('dashlets_live_priority');
        this.tooltipText = '';
        break;
      case EDashletType.OVER_TIME_STATUS:
        this.title = this.translationPipe.transform('dashlet_over_time_status');
        this.tooltipText = '';
        break;
      case EDashletType.OVER_TIME_PRIORITY:
        this.title = this.translationPipe.transform('dashlet_over_time_priority');
        this.tooltipText = '';
        break;
      case EDashletType.CURRENT_STACKED_STATUS:
        this.title = this.translationPipe.transform('dashlet_stacked_status');
        this.tooltipText = '';
        break;
      case EDashletType.COST_COMPARISON:
        this.title = this.translationPipe.transform('dashlet_cost_comparison');
        this.tooltipText = this.translationPipe.transform('dashlet_cost_comparison_tooltip');
        break;
      default:
        this.tooltipText = '';
        this.title = '';
    }
  }
}
