import { Component, Input, OnChanges } from '@angular/core';
import { ApiService } from '@core/http';
import { TPointCustomField } from '@project/view-models';
import { isNumber } from 'lodash';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { deconstructFormula } from 'src/app/project/components/formula-builder/utils/deconstruct-formula';
import {
  EFormulaPieceType,
  TFormulaPiece,
} from 'src/app/project/components/formula-builder/utils/formula-input.type';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getDecimalPlacesValue } from 'src/app/project/modules/custom-fields/custom-field-numbers/utils/getDecimalPlacesValue';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { getCustomFieldIcon } from 'src/app/project/modules/site/site-options/utils/get-field-icon';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-formula',
  templateUrl: './point-fields-formula.component.html',
  styleUrl: './point-fields-formula.component.scss',
})
export class PointFieldsFormulaComponent implements OnChanges {
  @Input() ppWorkspaceId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppField: TPointCustomField;
  @Input() ppFieldCurrencyCode: string;
  @Input() ppFieldCurrencySymbol: string;
  @Input() ppNew: boolean;

  value: string = '';
  EIconPath = EIconPath;
  unitSymbol: string;
  tooltipTitle: string;

  private decimalPlaces: number;
  private showCommas: boolean;
  private customField: TCustomField;

  constructor(
    private customFieldsService: CustomFieldsService,
    private translationPipe: TranslationPipe,
    private apiService: ApiService,
  ) {}

  ngOnChanges(): void {
    this.customField =
      this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[this.ppFieldId];

    if (this.customField) {
      this.setFormulaDisplayData();
      this.setTooltipTitle();

      this.value = this.formatValue(this.ppField.value);
    }
  }

  private setFormulaDisplayData(): void {
    switch (this.customField.outputType) {
      case ECustomFieldType.PERCENTAGE:
        this.decimalPlaces = 0;
        this.unitSymbol = '%';
        this.showCommas = false;
        break;
      case ECustomFieldType.NUMBERS:
        this.unitSymbol = this.customField.unit;
        this.showCommas = this.customField.showCommas;
        this.decimalPlaces = getDecimalPlacesValue(this.customField.decimalPlaces);

        break;
      case ECustomFieldType.COST:
        this.unitSymbol = this.ppFieldCurrencyCode + ' ' + this.ppFieldCurrencySymbol;
        this.showCommas = this.customField.showCommas;
        this.decimalPlaces = 2;

        break;
    }
  }

  private formatValue(value: string): string {
    if (this.ppNew) {
      return '';
    }

    if (value === null) {
      return this.translationPipe.transform('unable_to_calculate_formula');
    }

    let formattedValue = value;

    if (isNumber(this.decimalPlaces)) {
      formattedValue = Number(value).toFixed(this.decimalPlaces);
    }

    if (this.showCommas) {
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return formattedValue;
  }

  private setTooltipTitle(): void {
    const formulaPieces = deconstructFormula(this.customField.formula);
    const formulaHidden = formulaPieces.some(
      (piece) =>
        piece.type === EFormulaPieceType.CUSTOM_FIELD &&
        !this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[piece.value],
    );

    if (formulaHidden) {
      this.tooltipTitle = this.translationPipe.transform('formula_result');

      return;
    }

    const operatorsList = formulaPieces.map((piece) => this.getPieceHTML(piece));

    Promise.all(operatorsList).then((operatorsListResponse) => {
      const tooltip = `<div class="tooltipFormula">
      <div class="tooltipFormula__operator">f</div>
      ${operatorsListResponse.join('')}
    </div>`;

      this.tooltipTitle = tooltip;
    });
  }

  private getPieceHTML(piece: TFormulaPiece): Promise<string> {
    switch (piece.type) {
      case EFormulaPieceType.OPERATOR:
        return Promise.resolve(`<div class="tooltipFormula__operator">${piece.value}</div>`);
      case EFormulaPieceType.CUSTOM_FIELD: {
        return new Promise((resolve) => {
          const customField =
            this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[piece.value];
          const fieldIcon = getCustomFieldIcon(piece.value);

          getSvg(this.apiService, fieldIcon).then((svg) => {
            resolve(`<div class="tooltipFormula__customField">
              ${svg}
              <span>${customField.label}</span>
            </div>`);
          });
        });
      }
      default:
        return Promise.resolve('');
    }
  }
}
