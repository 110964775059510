<div class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_FORMULA_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      [ppTitle]="ppFieldLabel"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__formulaWrapper" ppTooltip [ppSanitize]="false" [ppTitle]="tooltipTitle">
    <div class="pointCF__formulaContent">
      <span class="customFieldCost__currency" *ngIf="unitSymbol"> {{ unitSymbol }} </span>

      <div class="pointCF__formula" [class.pointCF__formula--borderLeft]="!!unitSymbol">
        {{ value }}
      </div>
    </div>
  </div>
</div>
