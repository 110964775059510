<button
  class="dropdownItem dropdownItem--btn"
  id="editAssetButton"
  data-test="editAssetButton"
  (click)="editAsset()"
>
  {{ 'edit_asset' | ppTranslate }}
</button>

<button
  class="dropdownItem dropdownItem--btn"
  id="addDashletButton"
  data-test="addDashletButton"
  (click)="addDashlet()"
  *ngIf="canAddDashlets"
>
  {{ 'add_dashlet' | ppTranslate }}
</button>

<button
  class="dropdownItem dropdownItem--btn deleteAssetButton"
  id="deleteAssetButton"
  data-test="deleteAssetButton"
  (click)="deleteAsset()"
>
  {{ 'delete_asset' | ppTranslate }}
</button>
