import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { humanizeShareOption } from 'src/app/project/modules/share/share-utils/humanize-share';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { NewAssetSitePickerExpandingService } from '../new-asset-site-picker-expanding.service';
import { NewAssetSitePickerService } from '../new-asset-site-picker.service';

type TAssetCustomField = {
  label: string;
  value: string;
};

@Component({
  selector: 'pp-new-asset-site-picker-workspace',
  templateUrl: './new-asset-site-picker-workspace.component.html',
  styleUrls: ['./new-asset-site-picker-workspace.component.scss'],
})
export class NewAssetSitePickerWorkspaceComponent implements OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;

  workspace: TWorkspace;
  expanded = false;
  EIconPath = EIconPath;
  checked = false;
  role: string;
  customFields: TAssetCustomField[] = [];
  checkedCustomFieldId: string = '';

  private lowerCaseKeyword: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private newAssetSitePickerService: NewAssetSitePickerService,
    private newAssetSitePickerExpandingService: NewAssetSitePickerExpandingService,
  ) {
    this.lowerCaseKeyword = this.newAssetSitePickerService.getKeyword().toLowerCase();

    this.newAssetSitePickerService.keywordChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((keyword) => {
        this.lowerCaseKeyword = keyword.toLowerCase();
        this.setCustomFields();
      });
  }

  ngOnChanges(): void {
    this.workspace = this.workspaceService.getWorkspace(this.ppWorkspaceId);
    this.role = humanizeShareOption(this.workspace.share.shareOption as EUserRole);
    this.setCustomFields();
    this.checkInitialAssetSelection();
    this.expanded = this.newAssetSitePickerExpandingService.isWorkspaceExpanded(this.ppWorkspaceId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateChecked(): void {
    this.checked = !this.checked;

    if (this.checked) {
      this.newAssetSitePickerService.addAssetSite({
        workspaceId: this.ppWorkspaceId,
        budgetCustomFieldId: this.checkedCustomFieldId,
      });
    } else {
      this.checkedCustomFieldId = '';

      this.newAssetSitePickerService.removeAssetSite(this.ppWorkspaceId);
    }
  }

  updateCustomFieldChecked(customField: TAssetCustomField): void {
    if (this.checkedCustomFieldId === customField.value) {
      this.checkedCustomFieldId = '';
    } else {
      this.checked = true;
      this.checkedCustomFieldId = customField.value;
    }

    if (this.checked) {
      this.newAssetSitePickerService.addAssetSite({
        workspaceId: this.ppWorkspaceId,
        budgetCustomFieldId: this.checkedCustomFieldId,
      });
    }
  }

  updateExpanded(): void {
    this.expanded = !this.expanded;

    this.newAssetSitePickerExpandingService.setWorkspaceExpanded(this.ppWorkspaceId, this.expanded);
  }

  private checkInitialAssetSelection(): void {
    const assetSite = this.newAssetSitePickerService.getAssetSites()[this.ppWorkspaceId];

    if (assetSite) {
      this.checked = true;
      this.checkedCustomFieldId = assetSite.budgetCustomFieldId;
    }
  }

  private setCustomFields(): void {
    let costCustomFields: TAssetCustomField[] = [];

    const sortedCustomFields = this.getSortedWorkspaceCustomFields();

    sortedCustomFields
      .filter((customField) => this.matchesKeyword(customField))
      .forEach((customField) => {
        if (customField.type === ECustomFieldType.COST && customField.display) {
          costCustomFields.push({
            label: customField.label,
            value: customField.id.toString(), // TODO BE Returns number for some reason
          });
        }
      });

    this.customFields = costCustomFields;
  }

  private getSortedWorkspaceCustomFields(): TCustomField[] {
    const customFields = this.customFieldsService.getWorkspaceCustomFields(
      this.workspace.workspaceId,
    );

    const customFieldsList = Object.values(customFields);

    return customFieldsList.sort((customFieldA, customFieldB) => {
      return (
        this.workspace.customFields.indexOf(customFieldA.id.toString()) -
        this.workspace.customFields.indexOf(customFieldB.id.toString())
      );
    });
  }

  matchesKeyword(customField: TCustomField): boolean {
    return (
      customField.label.toLowerCase().includes(this.lowerCaseKeyword) ||
      this.workspace.siteName.toLowerCase().includes(this.lowerCaseKeyword) ||
      this.workspace.accountName.toLowerCase().includes(this.lowerCaseKeyword)
    );
  }
}
