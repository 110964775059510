import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';

import { TDashlet, TPreferences } from '../../preferences/preferences.model';

import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { ResponseErrorService } from '../../errors/response-error.service';

import { DOCUMENT } from '@angular/common';
import { DashboardApiProviderService } from '@core/api';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDashletPeriod } from '../dashboard-timeframes-enums';
import {
  TDashletCostComparisonRequestData,
  TDashletCostComparisonResponse,
  TDashletLiveResponse,
  TDashletReportResponse,
  TDashletRequestData,
} from '../dashboard.consts';
import { TDashletActivity } from '../dashlet/dashlet-activities/dashlet-activity/dashlet-activity.model';
import { EDashletType } from '../dashlets-enums';
import { generateNewDashlet } from '../utils/generate-new-dashlet';
import { DEFAULT_DASHLETS } from './default-dashlets';
import { mergeDashboardActivities } from './merge-dashboard-activities';
import { updateDashletSize } from './update-dashlet-size';

@Injectable({
  providedIn: 'root',
})
export class DashboardService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly newDashletPrefsUpdateRequested$ = new Subject<void>();

  private readonly _dashlets$ = new BehaviorSubject<TDashlet[]>([]);
  readonly dashlets$ = this._dashlets$.asObservable();

  activities: TDashletActivity[];

  private bodyFixedCssClass = 'fixed-width';
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private responseErrorService: ResponseErrorService,
    private preferencesService: PreferencesService,
    private dashboardApiProviderService: DashboardApiProviderService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initDashlets(): void {
    const preferences = this.preferencesService.getPreferences();

    if (preferences.dashlets && preferences.dashlets.data && preferences.dashlets.data.length > 0) {
      this._dashlets$.next([...preferences.dashlets.data]);
    } else {
      this.setDefaultDashlets();
    }

    const updatedDashlets = updateDashletSize(this._dashlets$.value);
    this._dashlets$.next([...updatedDashlets]);
  }

  getDashlets(): TDashlet[] {
    return this._dashlets$.value;
  }

  addDashlet(field: { value: EDashletType }): void {
    const newDashlet = generateNewDashlet(field.value);

    this._dashlets$.next([...this._dashlets$.value, newDashlet]);
    this.updateUserDashlets();
  }

  removeDashlet(dashlet: TDashlet): void {
    const dashlets: TDashlet[] = [...this._dashlets$.value];
    dashlets.splice(dashlets.indexOf(dashlet), 1);

    this._dashlets$.next(dashlets);
    this.updateUserDashlets();
  }

  updateUserDashlets(skipCheck: boolean = false): void {
    const oldPreferences = this.preferencesService.getPreferences();

    // Gridster plugin sends these requests even if nothing really changed
    if (!skipCheck && isEqual(oldPreferences?.dashlets?.data, this._dashlets$.value)) {
      return;
    }

    this.newDashletPrefsUpdateRequested$.next();

    const body: TPreferences = {
      dashlets: {
        data: this._dashlets$.value,
      },
    };

    timer(200)
      .pipe(
        takeUntil(this.newDashletPrefsUpdateRequested$),
        tap(() => {
          this.preferencesService
            .updatePreferences(body)
            .pipe(
              takeUntil(this.destroy$),
              tap(() => {
                const preferences = this.preferencesService.getPreferences();

                preferences.dashlets.data = this._dashlets$.value;
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  fetchCurrentTypeCount({
    type = '',
    accountId = null,
    workspaceIds = [],
  }: {
    type?: string;
    accountId?: string;
    workspaceIds?: string[];
  }): Observable<TDashletLiveResponse> {
    const body: TDashletRequestData = {
      ...(accountId ? { accountId } : {}),
      ...(workspaceIds.length ? { workspaceIds } : {}),
      type: type as EDashletType,
    };

    return this.dashboardApiProviderService
      .fetchCurrentTypeCount(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchStackedStatus({ accountId = '', workspaceIds = [] }) {
    const body: TDashletRequestData = {
      ...(accountId ? { accountId } : {}),
      workspaceIds,
      type: EDashletType.CURRENT_STACKED_STATUS,
    };

    if (body.workspaceIds.length === 0) {
      return of({});
    }

    return this.dashboardApiProviderService
      .fetchStackedStatus(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchCostComparison({
    assetId = '',
    workspacesCosts = {},
  }: {
    assetId: string;
    workspacesCosts: { [workspaceId: string]: string[] };
  }): Observable<TDashletCostComparisonResponse> {
    const body: TDashletCostComparisonRequestData = {
      assetId,
      workspacesCosts,
    };

    return this.dashboardApiProviderService
      .fetchCostComparison(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchReports({
    type = '',
    period = EDashletPeriod.WEEK,
    length = 5,
    accountId = null,
    workspaceIds = [],
  }: {
    type?: string;
    period?: string;
    length?: number;
    accountId?: string;
    workspaceIds?: string[];
  }): Observable<TDashletReportResponse> {
    const body: TDashletRequestData = {
      ...(accountId ? { accountId } : {}),
      ...(workspaceIds.length ? { workspaceIds } : {}),
      period: period as EDashletPeriod,
      type: type as EDashletType,
      length,
    };

    return this.dashboardApiProviderService
      .fetchReports(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  setDefaultDashlets(): void {
    const dashlets: TDashlet[] = [];

    DEFAULT_DASHLETS.forEach((dashlet) => {
      dashlets.push({ ...dashlet });
    });

    this._dashlets$.next(dashlets);

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__SET_DEFAULT, {
      event_category: EGoogleEventCategory.DASHBOARD,
    });

    this.updateUserDashlets();
  }

  mergeActivities(activity: TDashletActivity, index: number): void {
    return mergeDashboardActivities(activity, index, this.activities);
  }

  setActivities(activities: TDashletActivity[]): void {
    this.activities = activities;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setBodyTagFixed(): void {
    this.renderer.addClass(this.document.body, this.bodyFixedCssClass);
  }

  unsetBodyTagFixed(): void {
    this.renderer.removeClass(this.document.body, this.bodyFixedCssClass);
  }
}
