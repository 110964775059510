import { cloneDeep } from 'lodash';

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { forkJoin, Observable, Subject } from 'rxjs';

import { TUser } from 'src/app/project/modules/user/user.model';

import { ActiveService } from 'src/app/project/services/active/active.service';
import { PlanPinsService } from '../../plan/plan-pins.service';
import { SiteTableEventsService } from './site-table-events.service';

import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import { SET_POINTS } from 'src/app/project/modules/points/points.store';
import { CLEAR_COLLAPSED_GROUPS } from './table.ui.store';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import {
  TWorkspaceDataResponse,
  WorkspaceService,
} from 'src/app/project/modules/workspace/workspace.service';
import { SiteService } from '../site.service';

import { ApiService } from '@core/http';
import { select, Store } from '@ngrx/store';
import { TAllUsers } from '@project/view-models';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { WorkspaceApiProviderService } from 'src/app/project/data-providers/api-providers/workspace-api-provider/workspace-api-provider.service';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { TActive } from 'src/app/project/services/active/active.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TCustomField } from '../../custom-fields/custom-fields.model';
import { CustomFieldModelFactory } from '../../custom-fields/utils/custom-field-model-factory';
import { PointAttachmentsService } from '../../points/point-modal/point-attachments/point-attachments.service';
import { TPreferences, TWorkspacePreferences } from '../../preferences/preferences.model';
import { TSavedViews } from '../../saved-views/models/saved-views.model';
import { SavedViewsService } from '../../saved-views/saved-views.service';
import { SiteOptionsService } from '../site-options/site-options.service';
import { CustomTableService } from './custom-table/custom-table.service';
import Table from './custom-table/table/Table';
import { SiteTableService } from './site-table.service';

@Component({
  selector: 'pp-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.scss'],
})
export class SiteTableComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() ppWorkspaceId: string = null;
  @Input() ppHidden = false;
  @Input() ppWidth: number;
  @Input() ppFullWidth: boolean;

  @ViewChild('tableWrapper', { static: true }) tableWrapperElement: ElementRef;
  @ViewChild('table', { static: true }) tableElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  activePlan = GET_ACTIVE_PLAN();

  public users: TAllUsers = {};
  public user: TUser;

  customFields = null;
  private active$: Observable<TActive>;
  private table: Table;

  constructor(
    private store: Store<{ active: TActive }>,
    private siteTableEventsService: SiteTableEventsService,
    private activeService: ActiveService,
    private planPinsService: PlanPinsService,
    private preferencesService: PreferencesService,
    private userService: UserService,
    private siteService: SiteService,
    private workspaceService: WorkspaceService,
    private sitePointFilterService: SitePointFilterService,
    private pointsFetchingService: PointsFetchingService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
    private responseErrorService: ResponseErrorService,
    private pointAttachmentsService: PointAttachmentsService,
    private siteOptionsService: SiteOptionsService,
    private siteTableService: SiteTableService,
    private apiService: ApiService,
    private dropdownService: DropdownService,
    private customTableService: CustomTableService,
    private savedViewsService: SavedViewsService,
  ) {
    this.active$ = this.store.pipe(select(EStore.ACTIVE));

    this.active$.pipe(takeUntil(this.destroy$)).subscribe((active) => {
      if (this.table) {
        this.table.setActivePointId(active._id);
      }
    });
  }

  ngAfterViewInit() {
    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.table = this.createTableInstance();

          this.tableWrapperElement.nativeElement.replaceChild(
            this.table.element,
            this.tableElement.nativeElement,
          );

          this.loadWorkspace();
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes.ppWorkspaceId && !changes.ppWorkspaceId.isFirstChange()) {
      const workspaceId = this.activeService.getActiveWorkspaceId();
      const table = this.customTableService.getTable();

      table.clear();
      table.setWorkspaceId(workspaceId);

      this.loadWorkspace();
    } else if (changes.ppFullWidth) {
      const table = this.customTableService.getTable();

      if (table) {
        table.load(true);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  loadWorkspace(): void {
    const table = this.customTableService.getTable();
    const preferences = this.preferencesService.getPreferences();
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.siteTableService.setTableLoaded(false);
    this.savedViewsService.setSelectedViewId(workspaceId, null);

    SET_POINTS([]);

    forkJoin([
      this.workspaceService.generateWorkspace(workspaceId),
      this.workspaceService.fetchWorkspaceData(workspaceId),
      this.pointsFetchingService.fetchPoints(workspaceId),
    ])
      .pipe(
        takeUntil(this.destroy$),
        tap(([, workspaceDataResponse]) => {
          const workspace = workspaceDataResponse.workspace;
          const savedViews = this.savedViewsService.getSavedViews(workspaceId);
          const workspacePreferences = this.generateWorkspacePreferences(
            savedViews,
            preferences,
            workspaceId,
            workspaceDataResponse,
          );

          const workspaceUsers = workspaceDataResponse.workspaceUsers;
          this.siteTableService.processPreferencesResponse(
            cloneDeep(workspacePreferences),
            workspaceId,
          );
          this.sitePointFilterService.filterPoints(false);

          const sortButton = this.siteOptionsService.getSortButton();
          const groupButton = this.siteOptionsService.getGroupButton();

          this.customFields = workspace.customFields;

          if (sortButton) {
            sortButton.update();
          }

          if (groupButton) {
            groupButton.update();
          }

          CLEAR_COLLAPSED_GROUPS();

          this.sitePointFilterService.filterPoints(true);
          this.customTableService.checkColumns(this.customFields);
          table.updateVisibleColumnIndexes();
          table.setKeyword('');
          table.updateWidth();
          table.loadAvatars(workspaceUsers);
          table.load(true);

          this.siteService.setTableFetched(true);
          this.siteTableService.setTableLoaded(true);
        }),
      )
      .subscribe();
  }

  private generateWorkspacePreferences(
    savedViews: TSavedViews,
    preferences: TPreferences,
    workspaceId: string,
    workspaceDataResponse: TWorkspaceDataResponse,
  ): TWorkspacePreferences {
    if (savedViews) {
      let viewExists = false;

      if (savedViews.personal.defaultViewId) {
        viewExists = !!this.savedViewsService.getView(savedViews.personal.defaultViewId);
      }

      if (!viewExists && savedViews.shared.sharedDefaultViewId) {
        viewExists = !!this.savedViewsService.getView(savedViews.shared.sharedDefaultViewId);
      }

      if (viewExists) {
        return this.preferencesService.getCurrentWorkspacePrefs({
          preferences,
          workspaceId,
          savedViews,
        });
      } else {
        return cloneDeep(workspaceDataResponse.workspacePreferences);
      }
    } else {
      return cloneDeep(workspaceDataResponse.workspacePreferences);
    }
  }

  private createTableInstance(): Table {
    return new Table(false, {
      dropdownService: this.dropdownService,
      apiService: this.apiService,
      workspaceId: this.ppWorkspaceId,
      selectPointCallback: (_point): void => {
        this.siteTableEventsService.togglePointSelection({
          workspaceId: _point.workspaceRef.id,
          _id: _point._id,
        });
      },
      selectAllPointsCallback: (selectAll: boolean): void => {
        this.siteTableEventsService.togglePointsSelection(selectAll, this.ppWorkspaceId);
      },
      openPointCallback: (_point): void => {
        const attachmentUploading = this.pointAttachmentsService.getAttachmentUploading();

        if (!this.activePlan.active && !attachmentUploading) {
          this.siteTableEventsService.openPoint(
            { workspaceRef: _point.workspaceRef, _id: _point._id },
            { isOverview: false },
          );
        }
      },
      savePreferencesCallback: (): void => {
        if (this.ppWorkspaceId) {
          this.preferencesService.savePreferences(this.ppWorkspaceId);
        }
      },
      highlightPinCallback: (_pointId: number): void => {
        this.planPinsService.enlargePin(_pointId);
      },
      dehighlightPinCallback: (): void => {
        this.planPinsService.restorePin();
      },
      getCustomFieldsCallback: (): Observable<TCustomField[]> =>
        this.workspaceApiProviderService.fetchWorkspace(this.ppWorkspaceId).pipe(
          takeUntil(this.destroy$),
          map((response) =>
            response.customFields.map((customField) =>
              CustomFieldModelFactory.createFromDTO(customField),
            ),
          ),
          catchError(this.responseErrorService.handleRequestError),
        ),
      pointAttachmentsService: this.pointAttachmentsService,
      activePointId: this.activeService.getActivePointId(),
      siteOptionsService: this.siteOptionsService,
      customTableService: this.customTableService,
    });
  }
}
