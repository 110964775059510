import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, NgZone, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { TCombinedCustomField } from 'src/app/project/modules/custom-fields/combine-custom-fields/combined-custom-field.model';
import { TAccountUser } from 'src/app/project/modules/users/account.user.model';

@Component({
  selector: 'pp-user-management-user-section-sites',
  templateUrl: './user-management-user-section-sites.component.html',
  styleUrl: './user-management-user-section-sites.component.scss',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
        }),
      ),
      state(
        'closed',
        style({
          height: '0',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class UserManagementUserSectionSitesComponent implements OnChanges, AfterViewInit {
  @Input() ppUser: TAccountUser;
  @Input() ppIsClosing: boolean;
  @Input() ppCustomFields: TCombinedCustomField[];

  isOpen: boolean;

  constructor(
    private previousPageService: PreviousPageService,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  ngOnChanges(): void {
    if (this.ppIsClosing) {
      this.isOpen = false;
    }
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          this.isOpen = true;
        });
      });
    });
  }

  navigateToSite(workspaceId: string): void {
    this.previousPageService.setPreviousSiteUrl(this.router.url);
    this.router.navigate(['/settings/site', workspaceId, 'users']);
  }
}
