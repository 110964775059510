import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';

import { WindowService } from '@core/services';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EStore } from '../../../../shared/enums/store.enum';
import { EIntegrationStatus } from '../../../custom-fields/custom-fields.model';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { DashboardChartService } from '../../dashboard-chart/dashboard-chart.service';
import { DashboardReportService } from '../../dashboard-report.service';
import { DashboardService } from '../../dashboard-service/dashboard.service';
import { EDashletPeriod } from '../../dashboard-timeframes-enums';
import { TDashletGraph } from '../../dashlet-graph.model';
import { EDashletType } from '../../dashlets-enums';
import { EditCostComparisonDashletModalComponent } from '../dashlet-head/edit-cost-comparison-dashlet-modal/edit-cost-comparison-dashlet-modal.component';
import { TEditCostComparisonModalData } from '../dashlet-head/edit-cost-comparison-dashlet-modal/edit-cost-comparison-modal.consts';
import { DashletService } from '../dashlet-service/dashlet.service';
import { checkAccount } from './check-account';

Chart.register(ChartDataLabels);

@Component({
  selector: 'pp-dashlet',
  templateUrl: './dashlet.component.html',
  styleUrls: ['./dashlet.component.scss'],
})
export class DashletComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart', { static: false }) chartElement: ElementRef;
  @Input() ppDashlet: TDashlet;
  @Input() ppDefaultWorkspaces: string[];
  @Input() ppAssetId: string;
  @Input() ppCanEdit: boolean;
  @Output() ppUpdateUserDashlets = new EventEmitter();

  workspaces: TWorkspacesById;
  account: TAccount = null;
  dashletTypes = EDashletType;
  loading = false;
  isFlipped = false;
  totalPoints = 0;
  workspaceIds: string[] = [];
  selectedRange: TDashletSiteRange;
  showingEmptyCostComparisonMessage: boolean;
  showingWrongCurrenciesError: boolean;

  private readonly destroy$ = new Subject<void>();
  private workspaces$: Observable<TWorkspacesById>;
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private chart: Chart = null;
  private graph: TDashletGraph;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
    }>,
    private dashboardService: DashboardService,
    private dashletService: DashletService,
    private dashboardChartService: DashboardChartService,
    private dashboardReportService: DashboardReportService,
    private windowService: WindowService,
    private ngZone: NgZone,
    private modalService: ModalService,
    private customFieldsService: CustomFieldsService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnChanges(): void {
    this.setWorkspaceIds();
  }

  ngOnInit() {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;

      this.onWorkspacesUpdate();
    });

    this.accounts$.subscribe((accounts) => {
      this.accounts = accounts;

      this.setAccount();
    });

    this.selectedRange = this.getReportSelectedRange();
  }

  ngAfterViewInit(): void {
    this.windowService.resize$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (
        this.ppDashlet.name === EDashletType.OVER_TIME_STATUS ||
        this.ppDashlet.name === EDashletType.OVER_TIME_PRIORITY ||
        this.ppDashlet.name === EDashletType.CURRENT_STATUS ||
        this.ppDashlet.name === EDashletType.CURRENT_PRIORITY ||
        this.ppDashlet.name === EDashletType.CURRENT_STACKED_STATUS ||
        this.ppDashlet.name === EDashletType.COST_COMPARISON
      ) {
        this.updateCharts();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  toggleFlip(): void {
    this.isFlipped = !this.isFlipped;
  }

  selectSiteRange(data: TDashletSiteRange): void {
    if (data.workspaceIds) {
      this.ppDashlet.selectedRange.accountId = data.accountId;
      this.ppDashlet.selectedRange.workspaceIds = data.workspaceIds;
    } else if (data.accountId) {
      this.ppDashlet.selectedRange.accountId = data.accountId;
      this.ppDashlet.selectedRange.workspaceIds = [];
    } else {
      this.ppDashlet.selectedRange.accountId = null;
      this.ppDashlet.selectedRange.workspaceIds = [];
    }

    this.setAccount();
    this.updateData();

    this.setWorkspaceIds();
    this.ppUpdateUserDashlets.emit();
  }

  selectPeriod(data: EDashletPeriod): void {
    this.ppDashlet.period = data;

    this.updateData();

    this.ppUpdateUserDashlets.emit();
  }

  setupCostComparison(): void {
    this.modalService.setData<TEditCostComparisonModalData>({
      dashlet: this.ppDashlet,
      assetId: this.ppAssetId,
    });

    this.modalService.showModal(EditCostComparisonDashletModalComponent);
  }

  getReportSelectedRange(): TDashletSiteRange {
    const workspaceIds = this.ppDashlet.selectedRange.workspaceIds.filter(
      (workspaceId) => !!workspaceId && this.workspaces[workspaceId],
    ); // Remove nulls from preferences

    if (!this.ppDefaultWorkspaces) {
      return {
        accountId: this.ppDashlet.selectedRange.accountId,
        workspaceIds: workspaceIds,
      };
    }

    if (this.ppDashlet.name === EDashletType.CURRENT_STACKED_STATUS) {
      return this.getStackedStatusSelectedRange(workspaceIds);
    }

    if (this.ppDashlet.name === EDashletType.COST_COMPARISON) {
      return {
        accountId: null,
        workspaceIds: [],
        workspacesCosts: this.ppDashlet.selectedRange.workspacesCosts,
      };
    }

    if (this.ppDashlet.selectedRange.accountId) {
      const account = this.accounts.find(
        (account) => account.accountId === this.ppDashlet.selectedRange.accountId,
      );

      return {
        accountId: null,
        workspaceIds: this.ppDefaultWorkspaces.filter((workspaceId) => {
          return account.workspaces.includes(workspaceId);
        }),
      };
    }

    if (workspaceIds?.length) {
      return {
        accountId: null,
        workspaceIds: workspaceIds,
      };
    }

    return {
      accountId: null,
      workspaceIds: this.ppDefaultWorkspaces,
    };
  }

  private getStackedStatusSelectedRange(workspaceIds: string[]): TDashletSiteRange {
    return {
      accountId: workspaceIds.length ? null : '', // Empty string makes it so API returns nothing
      workspaceIds,
    };
  }

  private setWorkspaceIds(): void {
    if (this.ppDashlet.selectedRange.workspaceIds) {
      this.workspaceIds = this.ppDashlet.selectedRange.workspaceIds;
    } else if (this.ppDefaultWorkspaces) {
      this.workspaceIds = this.ppDefaultWorkspaces;
    } else {
      this.workspaceIds = [];
    }
  }

  private setAccount(): void {
    if (this.ppDashlet.selectedRange.accountId) {
      this.account = checkAccount(this.ppDashlet, this.accounts);

      if (!this.account) {
        this.ppDashlet.selectedRange.accountId = null;
        this.ppDashlet.selectedRange.workspaceIds = [];
      }
    }

    this.setWorkspaceIds();
  }

  private fetchCurrentTypeCount(type: EDashletType): void {
    this.loading = true;

    const requestData = this.getReportSelectedRange();

    this.dashboardService
      .fetchCurrentTypeCount({
        type,
        accountId: requestData.accountId,
        workspaceIds: requestData.workspaceIds,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          switch (type) {
            case EDashletType.CURRENT_STATUS: {
              const value = [
                response.statuses.OPEN,
                response.statuses.IN_PROGRESS,
                response.statuses.TO_REVIEW,
                response.statuses.ONHOLD,
                response.statuses.CLOSED,
                response.statuses.CANCELED,
              ];

              this.graph.data.datasets[0].data = value;
              this.totalPoints = value.reduce((a, b) => a + b, 0);

              this.graph.data.labels = this.dashboardChartService.addGraphLabels(type, value);
              break;
            }
            case EDashletType.CURRENT_PRIORITY: {
              const value = [
                response.priorities.HIGH,
                response.priorities.MEDIUM,
                response.priorities.LOW,
              ];

              this.totalPoints = value.reduce((a, b) => a + b, 0);
              this.graph.data.datasets[0].data = value;

              this.graph.data.labels = this.dashboardChartService.addGraphLabels(type, value);
              break;
            }
          }

          this.addChartToView();
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  private fetchCostComparison(): void {
    this.loading = true;

    const requestData = this.getReportSelectedRange();
    Object.keys(requestData.workspacesCosts).forEach((workspaceId) => {
      const workspaceExists = this.workspaces[workspaceId];

      if (!workspaceExists) {
        delete requestData.workspacesCosts[workspaceId];
      }
    });

    if (Object.keys(requestData.workspacesCosts).length === 0) {
      this.loading = false;

      this.showEmptyDataSourceError();

      return;
    }

    const wrongCurrenciesError = this.checkWrongCurrencies(requestData);

    if (wrongCurrenciesError) {
      this.loading = false;

      this.showWrongCurrenciesError();

      return;
    } else {
      this.showingWrongCurrenciesError = false;
    }

    this.dashboardService
      .fetchCostComparison({
        assetId: this.ppAssetId,
        workspacesCosts: requestData.workspacesCosts,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.graph = this.dashletService.createCostComparisonGraph(response, false);
          this.addChartToView();
        }),
        finalize(() => {
          this.showingEmptyCostComparisonMessage = false;
          this.loading = false;
        }),
      )
      .subscribe();
  }

  private showWrongCurrenciesError(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.showingWrongCurrenciesError = true;
      });
    });
  }

  private checkWrongCurrencies(requestData: TDashletSiteRange): boolean {
    let wrongCurrenciesError = false;

    Object.keys(requestData.workspacesCosts).forEach((workspaceId) => {
      const requestWorkspaces = requestData.workspacesCosts[workspaceId];

      const firstCustomField = this.customFieldsService.getWorkspaceCustomField(
        workspaceId,
        requestWorkspaces[0],
      );
      const secondCustomField = this.customFieldsService.getWorkspaceCustomField(
        workspaceId,
        requestWorkspaces[1],
      );

      if (
        !firstCustomField ||
        !secondCustomField ||
        firstCustomField.currencyCode !== secondCustomField.currencyCode ||
        !firstCustomField.display ||
        !secondCustomField.display ||
        firstCustomField.volyIntegrationActive === EIntegrationStatus.DISABLED ||
        secondCustomField.volyIntegrationActive === EIntegrationStatus.DISABLED
      ) {
        wrongCurrenciesError = true;
      }
    });
    return wrongCurrenciesError;
  }

  private showEmptyDataSourceError(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.showingEmptyCostComparisonMessage = true;
      });
    });
  }

  private fetchStackedStatus(): void {
    this.loading = true;

    const requestData = this.getReportSelectedRange();

    this.dashboardService
      .fetchStackedStatus({
        accountId: requestData.accountId,
        workspaceIds: requestData.workspaceIds,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.graph = this.dashletService.createStackedStatusGraph(response, false);
          this.addChartToView();
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  private fetchReports(type: EDashletType): void {
    this.loading = true;

    const reportSelectedRange = this.getReportSelectedRange();

    this.dashboardReportService
      .fetchReports(
        this.ppDashlet,
        this.graph,
        reportSelectedRange.workspaceIds,
        reportSelectedRange.accountId,
      )
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.loading = false;
          this.totalPoints = response.totalPoints;
          this.graph = response.graph;

          this.dashboardChartService.addGraphDatasetLabels(this.graph, type, response.response);

          this.addChartToView();
        }),
      )
      .subscribe();
  }

  private addChartToView(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    const ctx = this.chartElement.nativeElement.getContext('2d');

    this.ngZone.runOutsideAngular(() => {
      this.chart = new Chart(ctx, this.graph);
    });
  }

  private updateData(): void {
    switch (this.ppDashlet.name) {
      case EDashletType.CURRENT_STATUS:
        this.fetchCurrentTypeCount(EDashletType.CURRENT_STATUS);
        break;
      case EDashletType.CURRENT_PRIORITY:
        this.fetchCurrentTypeCount(EDashletType.CURRENT_PRIORITY);
        break;
      case EDashletType.OVER_TIME_STATUS:
        this.fetchReports(EDashletType.OVER_TIME_STATUS);
        break;
      case EDashletType.OVER_TIME_PRIORITY:
        this.fetchReports(EDashletType.OVER_TIME_PRIORITY);
        break;
      case EDashletType.CURRENT_STACKED_STATUS:
        this.fetchStackedStatus();
        break;
      case EDashletType.COST_COMPARISON:
        this.fetchCostComparison();
        break;
      default:
        break;
    }

    this.selectedRange = this.getReportSelectedRange();
  }

  private updateCharts(): void {
    this.graph = this.dashletService.getDashletGraph(this.ppDashlet.name);
    this.updateData();
  }

  private onWorkspacesUpdate(): void {
    if (this.ppDashlet.name === EDashletType.ACTIVITIES) {
      if (Object.keys(this.workspaces).length > 0 && this.ppDashlet.selectedRange.workspaceIds) {
        this.ppDashlet.selectedRange.workspaceIds =
          this.ppDashlet.selectedRange.workspaceIds.filter((workspaceId) => {
            return this.workspaces[workspaceId];
          });

        this.setWorkspaceIds();
        this.ppUpdateUserDashlets.emit();
      }
    }
  }
}
