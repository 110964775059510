import { Injectable } from '@angular/core';
import { translate } from 'src/app/project/features/translate/translate';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { TDashletCostComparisonResponse } from '../../dashboard.consts';
import { TDashletGraph, TDashletGraphDataset } from '../../dashlet-graph.model';
import { generateCostComparisonGraphOptions } from './generate-cost-comparison-graph-options';

@Injectable({
  providedIn: 'root',
})
export class CreateCostComparisonGraphService {
  constructor(private workspaceService: WorkspaceService) {}

  createCostComparisonGraph(
    response: TDashletCostComparisonResponse,
    isExport: boolean,
  ): TDashletGraph {
    const labels = Object.keys(response.workspacesCosts).map((workspaceId) => {
      const workspace = this.workspaceService.getWorkspace(workspaceId);

      return workspace ? workspace.siteName : '';
    });

    const data: {
      labels: string[];
      datasets: TDashletGraphDataset[];
    } = {
      labels: labels,
      datasets: [
        {
          label: translate('cost_comparison_budget'),
          data: Object.values(response.workspacesCosts).map((costs) => costs[0]),
          backgroundColor: '#002060',
          barPercentage: 1.0,
          categoryPercentage: 0.66,
        },
        {
          label: translate('cost_comparison_cost'),
          data: Object.values(response.workspacesCosts).map((costs) => costs[1]),

          backgroundColor: Object.values(response.workspacesCosts).map((costs) => {
            return costs[1] > costs[0] ? '#DA3030' : '#00B0F0';
          }),
          barPercentage: 1.0,
          categoryPercentage: 0.66,
        },
      ],
    };

    return {
      type: 'bar',
      data: data,
      options: generateCostComparisonGraphOptions(isExport),
    };
  }
}
