import * as XLSX from 'xlsx';

import { Inject, Injectable } from '@angular/core';
import { TAllUsers } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { SiteTablePointsService } from 'src/app/project/modules/site/site-table/site-table-points.service';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { removeSpecialCharactersFromText } from '../../../core/helpers/format-text';
import { DownloadService } from '../../services/download.service';
import { TCustomField } from '../custom-fields/custom-fields.model';
import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { GET_CUSTOM_FIELDS } from '../custom-fields/custom-fields.store';
import { TPoint, TPointsByWorkspace } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { getSelectedPoints } from '../points/selected-points';
import { getVisiblePoints } from '../points/visible-points';
import { getGroupValueById } from '../site/site-table/body-elements/body-row/getGroupValueById';
import { TColumn } from '../site/site-table/columns/column.model';
import { GET_GROUPING } from '../site/site-table/columns/grouping.store';
import { TableColumnsService } from '../site/site-table/columns/table-columns.service';
import { GET_TABLE } from '../site/site-table/table.ui.store';
import { checkPointTimelineField } from './local/check-point-timeline-field';
import { fillColumnValue } from './local/fill-column-value';
import { generateExcelHeaders } from './local/generate-excel-headers';
import { generateGroupCellValue } from './local/generate-group-cell-value';
import { getNumbersExportFormat } from './local/get-numbers-export-format';
import { PointExportFilterService } from './point-export-filter.service';

export type TExportColumn = {
  index?: number;
  name: string;
  width?: number;
  timeline?: boolean;
  customFieldIds?: string[];
};

export type TExportColumnWidths = {
  wch: number;
};

@Injectable({
  providedIn: 'root',
})
export class PointExportLocalService {
  private excel_headers: string[];

  columnFormatMap: { [key: string]: { format: string; type: string } } = {};
  groupDepth = 0;

  constructor(
    @Inject('ORIGIN') private locationOrigin: string,
    private pointsService: PointsService,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private usersService: UsersService,
    private siteTablePointsService: SiteTablePointsService,
    private pointExportFilterService: PointExportFilterService,
    private downloadService: DownloadService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private customFieldsService: CustomFieldsService,
    private tableColumnsService: TableColumnsService,
  ) {}

  exportLocally(type: string): void {
    const allPoints = this.pointsService.getAllPoints();
    const workspace = this.workspaceService.getActiveWorkspace();
    const workspaces = this.workspaceService.getWorkspaces();
    const user: TUser = this.userService.getUser();
    const users: TAllUsers = this.usersService.getUsers();
    const tableToExport: string[][] = [];
    const workbook = XLSX.utils.book_new();
    const columns: {
      wch: number;
    }[] = [];

    let points: TPoint[] = [];
    let selectedPoints: TPoint[] = [];

    this.groupDepth = GET_GROUPING().length;

    const { name, exportName } = this.getName(workspace, type);

    // Break reference, otherwise we get Timeline (Start) field in columns in filters
    const visibleColumns: TColumn[] = cloneDeep(
      this.tableColumnsService
        .getColumns()
        .sort((firstColumn, secondColumn) =>
          firstColumn.index > secondColumn.index
            ? 1
            : firstColumn.index < secondColumn.index
              ? -1
              : 0,
        )
        .filter((_column) => !_column.hidden),
    );

    selectedPoints = this.getSelectedPoints(workspace, selectedPoints, allPoints);

    ({ points } = this.getPoints(selectedPoints, workspace, allPoints));

    const promptText = this.translationPipe.transform(
      points.length > 1 ? 'prompt_export_start' : 'prompt_export_start_single',
    );

    this.promptService.showSuccess(promptText, { duration: 15 });
    this.fillColumns(points, visibleColumns, workspaces, users, tableToExport, type);
    this.getIndexesToDouble(visibleColumns);
    this.setColumnFormatting(visibleColumns);
    columns.push(...this.setColumnWidths(visibleColumns));
    this.setColumnHeaders(visibleColumns, tableToExport);

    workbook.Props = {
      Title: name,
      Subject: 'Export',
      Author: user.userName,
      CreatedDate: new Date(),
    };

    workbook.SheetNames.push(name);

    const worksheet_data = tableToExport;
    let worksheet = XLSX.utils.aoa_to_sheet(worksheet_data);

    worksheet['!cols'] = columns;

    worksheet = this.formatCols(visibleColumns, worksheet, tableToExport.length);

    workbook.Sheets[name] = worksheet;

    this.saveFile(type, worksheet, workbook, exportName);
  }

  formatCols(columns: TColumn[], worksheet: XLSX.WorkSheet, rows: number): XLSX.WorkSheet {
    this.excel_headers = generateExcelHeaders(columns.length + this.groupDepth);
    for (let i = this.groupDepth; i < columns.length + this.groupDepth; i++) {
      if (this.columnFormatMap[i]) {
        worksheet = this.formatCellsInColumn(
          rows,
          this.excel_headers[i],
          worksheet,
          this.columnFormatMap[i],
        );
      }
    }

    return worksheet;
  }

  setColumnWidths(columns: TColumn[]): { wch: number }[] {
    const columnWidths: {
      wch: number;
    }[] = [];

    for (let i = 1; i <= this.groupDepth; i++) {
      columnWidths.push({ wch: 12.5 });
    }

    columns.forEach((_column) => {
      columnWidths.push({ wch: _column.width / 5 });
    });

    return columnWidths;
  }

  formatCellsInColumn(
    rowCount: number,
    column: string,
    workspace: XLSX.WorkSheet,
    columnFormatting,
  ): XLSX.WorkSheet {
    for (let i = 0; i < rowCount - 1; i++) {
      //The 2 accounts for ignoring the header and since excel starts with 1
      let cell = column + (i + 2);
      if (workspace[cell] && workspace[cell].v !== '-') {
        workspace[cell].z = columnFormatting.format;
        workspace[cell].t = columnFormatting.type;
      }

      //Use this to apply a bold style to group rows when we upgrade sheetJs to pro
      // if (tableRows[i].id) {
      //    workspace[cell].s = { font: { bold: true } };
      // }
    }
    return workspace;
  }

  private setColumnHeaders(visibleColumns: TExportColumn[], tableToExport: string[][]): void {
    const columnHeaders = visibleColumns.map((column) => column.name);
    columnHeaders.push('Point URL');

    const priorityColumnIndex = columnHeaders.indexOf('Priority');
    const statusColumnIndex = columnHeaders.indexOf('Status');

    if (priorityColumnIndex > -1) {
      columnHeaders[priorityColumnIndex] = 'Priority';
    }

    if (statusColumnIndex > -1) {
      columnHeaders[statusColumnIndex] = 'Status';
    }

    let headersWithGroupSpacing = [];

    for (let i = 1; i <= this.groupDepth; i++) {
      headersWithGroupSpacing.push('');
    }

    tableToExport.unshift([...headersWithGroupSpacing, ...columnHeaders]);
  }

  private saveFile(
    type: string,
    worksheet: XLSX.WorkSheet,
    workbook: XLSX.WorkBook,
    exportName: string,
  ): void {
    let workbook_out;

    if (type === 'csv') {
      workbook_out = XLSX.utils.sheet_to_csv(worksheet, { strip: true });
    } else {
      workbook_out = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    }

    const exportData = new Blob([this.binaryToOcetStream(workbook_out)], {
      type: 'application/octet-stream',
    });

    this.downloadService.saveFile(exportData, exportName);
  }

  private getIndexesToDouble(visibleColumns: TExportColumn[]): void {
    const indexesToDouble: number[] = [];

    for (let i = 0; i < visibleColumns.length; i++) {
      const column = visibleColumns[i];

      if (column.timeline) {
        indexesToDouble.push(i);
      }
    }

    indexesToDouble
      .sort()
      .reverse()
      .forEach((index) => {
        visibleColumns.splice(index + 1, 0, {
          name: visibleColumns[index].name + ' (End)',
          width: visibleColumns[index].width,
          customFieldIds: visibleColumns[index].customFieldIds,
        });

        visibleColumns[index].name = visibleColumns[index].name + ' (Start)';
      });
  }

  private fillColumns(
    points: TPoint[],
    visibleColumns: TColumn[],
    workspaces: TWorkspacesById,
    users: TAllUsers,
    tableToExport: string[][],
    type: string,
  ): void {
    const table = GET_TABLE();
    const tableItems = table.selectedPoints.size > 0 ? table.getSelectedItems() : table.items;

    tableItems.forEach((item) => {
      //ToDo change the items of table to use point ID instead of index of point
      if (item && (item.index >= 0 || (item.pointId && table.selectedPoints.has(item.pointId)))) {
        this.fillWithPointData(
          item.index,
          item.pointId,
          table.points,
          visibleColumns,
          workspaces,
          users,
          tableToExport,
          type,
          points.map((point) => point._id),
        );
      } else if (item && item.id) {
        const GroupValue = getGroupValueById(item.id);

        this.fillWithPointGroupData(
          item.id,
          GroupValue.pointObjects,
          visibleColumns,
          tableToExport,
          GroupValue.value,
          GroupValue.depth,
        );
      }
    });
  }

  private fillWithPointGroupData(
    id: string,
    points: TPoint[],
    visibleColumns: TColumn[],
    tableToExport: string[][],
    groupTitle: string,
    groupLevel: number,
  ): void {
    const newArray: string[] = [];

    //Fills and indents first cols based on group depth
    for (let i = 1; i <= this.groupDepth; i++) {
      if (i === groupLevel) {
        newArray.push(groupTitle + '(' + points.length + ')');
      } else {
        newArray.push('');
      }
    }

    const customFields = GET_CUSTOM_FIELDS();
    this.groupDepth = GET_GROUPING().length;

    visibleColumns.forEach((column: TColumn & { timeline: boolean }) => {
      column.timeline = checkPointTimelineField(column, this.customFieldsService);

      if (column.timeline) {
        newArray.push('');
        newArray.push('');
        return;
      }
      newArray.push(generateGroupCellValue(column, points, customFields));
    });

    tableToExport.push(newArray);
  }

  private fillWithPointData(
    index: number,
    pointId: string,
    points: TPoint[],
    visibleColumns: TColumn[],
    workspaces: TWorkspacesById,
    users: TAllUsers,
    tableToExport: string[][],
    type: string,
    visiblePoints: string[],
  ): void {
    let point =
      index >= 0
        ? points[index]
        : points.find((p) => {
            return p._id === pointId;
          });

    if (!visiblePoints.includes(point._id)) {
      return;
    }

    const newArray: string[] = [];

    for (let i = 1; i <= this.groupDepth; i++) {
      newArray.push('');
    }

    visibleColumns.forEach((column: TColumn & { timeline?: boolean }) => {
      const value = fillColumnValue(
        column,
        point,
        workspaces,
        users,
        newArray,
        type,
        this.customFieldsService,
      );

      newArray.push(value);
    });

    newArray.push(this.locationOrigin + '/#/site/' + point.workspaceRef.id + '/point/' + point._id);

    tableToExport.push(newArray);
  }

  private getPoints(
    selectedPoints: TPoint[],
    workspace: TWorkspace,
    allPoints: TPointsByWorkspace,
  ): {
    points: TPoint[];
  } {
    let points = [];
    let visiblePoints = [];

    if (selectedPoints.length > 0) {
      points = this.siteTablePointsService.sortPoints(selectedPoints);
    } else {
      if (workspace) {
        visiblePoints = this.pointsService.getVisiblePoints();
      } else {
        const visiblePointsIds = getVisiblePoints();

        Object.keys(allPoints).forEach((key) => {
          visiblePoints = [
            ...visiblePoints,
            ...allPoints[key].entities.filter((point) => visiblePointsIds.includes(point._id)),
          ];
        });
      }

      if (visiblePoints.length > 0) {
        points = this.siteTablePointsService.sortPoints(visiblePoints);
      } else {
        if (workspace) {
          points = this.pointsService.getPoints();
        } else {
          Object.keys(allPoints).forEach((key) => {
            points = [...points, ...allPoints[key].entities];
          });
        }

        points = this.siteTablePointsService.sortPoints(points);
      }
    }

    points = this.pointExportFilterService.filterPoints(points);
    return { points };
  }

  private getSelectedPoints(
    workspace: TWorkspace,
    selectedPoints: TPoint[],
    allPoints: TPointsByWorkspace,
  ): TPoint[] {
    if (workspace) {
      selectedPoints = this.pointsService.getSelectedPoints();
    } else {
      const selectedPointsIds = getSelectedPoints();

      Object.keys(allPoints).forEach((key) => {
        selectedPoints = [
          ...selectedPoints,
          ...allPoints[key].entities.filter((point) => selectedPointsIds.includes(point._id)),
        ];
      });
    }
    return selectedPoints;
  }

  private getName(
    workspace: TWorkspace,
    type: string,
  ): {
    name: string;
    exportName: string;
  } {
    let name = 'overview_export.' + type;
    let exportName = name;

    if (workspace) {
      const accountName = workspace.accountName;
      const siteName = workspace.siteName;

      name = accountName + ' - ' + siteName + '.' + type;
      exportName = name;

      if (name.length > 30) {
        name = siteName + '.' + type;
      }

      if (name.length > 30) {
        name = 'site_export.' + type;
      }
    }

    name = removeSpecialCharactersFromText(name);
    return { name, exportName };
  }

  private binaryToOcetStream(s: string): ArrayBuffer {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }

    return buffer;
  }

  private setColumnFormatting(visibleColumns: TColumn[]): void {
    this.columnFormatMap = {};

    visibleColumns.forEach((column, columnIndex) => {
      columnIndex = columnIndex + this.groupDepth;
      if (column.customFieldIds) {
        const customFieldId = column.customFieldIds[0];
        const customField = GET_CUSTOM_FIELDS()[customFieldId];

        switch (customField.type) {
          case ECustomFieldType.COST: {
            this.formatCost(customField, columnIndex);
            break;
          }
          case ECustomFieldType.NUMBERS:
            this.formatNumbers(columnIndex, customField);
            break;
          case ECustomFieldType.PERCENTAGE:
            this.formatPercentage(columnIndex);

            break;
          case ECustomFieldType.FORMULA:
            switch (customField.outputType) {
              case ECustomFieldType.PERCENTAGE:
                this.formatPercentage(columnIndex);
                break;
              case ECustomFieldType.COST:
                this.formatCost(customField, columnIndex);
                break;
              case ECustomFieldType.NUMBERS:
                this.formatNumbers(columnIndex, customField);
                break;
            }
            break;
          default:
            this.columnFormatMap[columnIndex] = {
              format: '',
              type: '',
            };
        }
      } else {
        this.columnFormatMap[columnIndex] = {
          format: '',
          type: '',
        };
      }
    });
  }

  private formatNumbers(columnIndex: number, customField: TCustomField): void {
    this.columnFormatMap[columnIndex] = {
      format: getNumbersExportFormat(+customField.decimalPlaces, customField.unit),
      type: 'n',
    };
  }

  private formatCost(customField: TCustomField, columnIndex: number): void {
    const currencySymbol = customField.currencySymbol;
    this.columnFormatMap[columnIndex] = {
      format: '"' + currencySymbol + '"#,##0.00_);\\("' + currencySymbol + '"#,##0.00\\)',
      type: 'n',
    };
  }

  private formatPercentage(columnIndex: number): void {
    this.columnFormatMap[columnIndex] = { format: '0%', type: 'n' };
  }
}
