<section class="siteSettingsIntegrationsCustomFields">
  <div class="integrationFieldsDescription">
    <header class="integrationFieldsDescription__header">
      {{ 'integrations_voly_custom_fields_header' | ppTranslate }}
    </header>

    <p class="integrationFieldsDescription__subheader">
      {{ 'integrations_voly_custom_fields_subheader' | ppTranslate }}
    </p>
  </div>

  <div class="integrationFields">
    <div class="integrationFieldContainer" *ngFor="let field of integrationFields; let last = last">
      <div class="integrationField">
        <pp-toggle
          [ppChecked]="field.active"
          ppStyle="filter"
          [ppDisabled]="disabled"
          (ppAction)="toggleField(field)"
        ></pp-toggle>

        <span class="integrationField__name">
          <img [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_BIG" />

          {{ field.fieldName }}
        </span>
      </div>

      <hr *ngIf="!last" class="integrationFieldSpacer" />
    </div>
  </div>
</section>
