import { TCurrencyCode, TCurrencySymbol } from './currencies/currencies.consts';
import { ECustomFieldType } from './custom-field-types-enums';

export type TWorkspaceCustomFields = {
  [customFieldTemplateId: string]: TCustomField;
};

export type TAllCustomFields = {
  [workspaceId: string]: TWorkspaceCustomFields;
};

export type TCustomFieldList = Partial<TCustomField> & {
  fieldError?: boolean;
  label: string;
  subList?: TCustomFieldList[];
  path?: string;
  level?: number;
};

export type TCustomField = {
  label: string;
  id: string;
  maxListIndex?: number;
  subList?: TCustomFieldList[];
  currencyCode?: TCurrencyCode;
  currencySymbol?: TCurrencySymbol;
  type?: string;
  unit?: string;
  decimalPlaces?: string | number;
  showTotal?: boolean;
  showCommas?: boolean;
  showHoursOnly?: boolean;
  fieldError?: boolean;
  workspaceId: string;
  lockedTemplate: boolean;
  lockedValue: boolean;
  display: boolean;
  volyIntegrationActive: EIntegrationStatus;
  outputType?: ECustomFieldType;
  formula?: string;
  nestingLevel?: number;
};

export enum EIntegrationStatus {
  OFF = 'OFF',
  ON = 'ON',
  DISABLED = 'DISABLED',
}

export type TCustomFieldDTO = {
  label: string;
  id: string;
  maxListIndex?: number;
  subList?: TCustomFieldList[];
  currencyCode?: TCurrencyCode;
  currencySymbol?: TCurrencySymbol;
  type?: string;
  unit?: string;
  decimalPlaces?: string | number;
  showTotal?: boolean;
  showCommas?: boolean;
  showHoursOnly?: boolean;
  workspaceId: string;
  lockedTemplate?: boolean;
  lockedValue?: boolean;
  display?: boolean;
  volyIntegrationActive?: boolean;
  outputType?: ECustomFieldType;
  formula?: string;
  nestingLevel?: number;
};

export type TCustomFields = {
  [customFieldTemplateId: string]: TCustomField;
};
