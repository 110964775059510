import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { UpdatePointTitle } from '../../points.actions';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { SyncService } from 'src/app/project/modules/offline/sync.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointActivityService } from '../point-timeline/point-activity.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { ClickOutsideHandler } from '@core/services';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';
import { PointHeaderService } from './point-header.service';

@Component({
  selector: 'pp-point-header',
  templateUrl: './point-header.component.html',
  styleUrls: ['./point-header.component.scss'],
})
export class PointHeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('pointTitle') pointTitleEl: ElementRef;
  @Input() ppPointId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppId: string | number;
  @Input() ppTitle: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  private readonly destroy$ = new Subject<void>();

  offline$: Observable<boolean>;
  offline = false;

  processing = false;
  success = false;
  error = false;

  private clickOutsideHandler: ClickOutsideHandler;
  private successTimerMs = 2500;
  private removeClickListenerTimerMs = 100;

  constructor(
    private store: Store<{ offline: boolean }>,
    private ngZone: NgZone,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private pointsUpdateService: PointsUpdateService,
    private syncService: SyncService,
    private translationPipe: TranslationPipe,
    private sitePointFilterService: SitePointFilterService,
    private pointHeaderService: PointHeaderService,
    private customTableService: CustomTableService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });
  }

  ngAfterViewInit() {
    this.clickOutsideHandler = new ClickOutsideHandler(
      this.pointTitleEl.nativeElement,
      this.destroy$,
    );
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnChanges() {
    this.error = false;

    if (!this.ppNew && this.pointTitleEl?.nativeElement) {
      this.pointTitleEl.nativeElement.scrollTop = 0;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateTitle(): void {
    const _id = this.ppPointId;

    this.error = false;
    this.pointHeaderService.setPointTitle(this.ppTitle);

    if (this.pointTitleEl) {
      this.pointTitleEl.nativeElement.blur();
    }

    if (this.ppNew) {
      this.updateTitleForNewPoint(_id);

      return;
    }

    if (!this.ppTitle?.trim()) {
      this.handleEmptyTitleSave();

      return;
    }

    this.processing = true;

    this.pointsUpdateService
      .updatePointField(_id, { title: this.ppTitle })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_title_update');

          this.promptService.showSuccess(promptText);
          this.sitePointFilterService.filterPoints(true);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          }

          this.error = true;

          this.promptService.showError(promptText);
          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  onFocus(): void {
    this.clickOutsideHandler.enable();
  }

  onBlur(): void {
    this.ngZone.runOutsideAngular(() => {
      timer(this.removeClickListenerTimerMs).subscribe(() => {
        this.clickOutsideHandler.disable();
      });
    });
  }

  blurInput(event: Event): void {
    const target = event.target as HTMLElement;

    target.blur();
  }

  private onClickOutside(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.pointTitleEl.nativeElement.blur();
  }

  private updateTitleForNewPoint(pointId: string): void {
    const title = this.ppTitle || this.pointTitleEl.nativeElement.value;

    this.store.dispatch(
      new UpdatePointTitle({
        workspaceId: this.ppWorkspaceId,
        _id: pointId,
        title,
      }),
    );
  }

  private handleEmptyTitleSave(): void {
    const promptText = this.translationPipe.transform('prompt_title_empty');
    const startTime = this.syncService.getStartTime();
    const errorTime = new Date();
    const timeDifference = errorTime.getTime() - startTime.getTime();
    let titleFieldValue = 'Title field not found';

    this.error = true;

    if (this.pointTitleEl.nativeElement?.value !== undefined) {
      titleFieldValue = this.pointTitleEl.nativeElement.value;
    }

    logErrorInSentry(
      new Error(
        `Missing point title ${this.ppTitle} and input value ${titleFieldValue} at time difference ${timeDifference}`,
      ),
    );

    this.promptService.showError(promptText);
  }

  onTitleRendered(): void {
    if (this.ppNew) {
      this.pointTitleEl.nativeElement.focus();
    }
  }
}
