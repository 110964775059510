import { Injectable } from '@angular/core';

import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { UserService } from '../user/user.service';
import { canEditPoint } from './share-utils/share-permissions';
import { TGranularPermission, TShare } from './share.model';

import { GET_SHARE } from './shares.store';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private customFieldsService: CustomFieldsService,
    private userService: UserService,
  ) {}

  checkForEditableFields(_workspaceId: string): {
    allFieldsEnabled: boolean;
    editableCustomFieldIds: string[];
  } {
    const share = GET_SHARE(_workspaceId);
    const user = this.userService.getUser();

    if (share) {
      const userCanEditPoint = canEditPoint(share.shareOption, user);

      return {
        allFieldsEnabled: false,
        editableCustomFieldIds: this.getEditableFields(share, _workspaceId, userCanEditPoint),
      };
    } else {
      return {
        allFieldsEnabled: true,
        editableCustomFieldIds: [],
      };
    }
  }

  private getEditableFields(share: TShare, _workspaceId: string, userCanEditPoint: boolean) {
    const editableCustomFieldsIds: string[] = [];
    const existingFields = this.customFieldsService.getCustomFields();
    const customFields: {
      [customFieldId: string]: TGranularPermission;
    } = {};

    share.advancedAccessLevels?.customFields.forEach((_customField) => {
      customFields[_customField.templateId] = _customField.permission;
    });

    Object.keys(existingFields[_workspaceId]).forEach((customFieldId) => {
      const customField = existingFields[_workspaceId][customFieldId];
      const displayOnly = customField.lockedValue;
      const fieldPermissions = customFields[customField.id.toString()];

      if (fieldPermissions?.edit && !displayOnly) {
        editableCustomFieldsIds.push(customField.id.toString());
      } else if (userCanEditPoint && !displayOnly) {
        editableCustomFieldsIds.push(customField.id.toString());
      }
    });

    return editableCustomFieldsIds;
  }

  areActivitiesEnabled(_workspaceId: string): boolean {
    const share = GET_SHARE(_workspaceId);

    if (
      share &&
      share.advancedAccessLevels &&
      share.advancedAccessLevels.timeline.permission &&
      typeof share.advancedAccessLevels.timeline.permission.read !== 'undefined' &&
      !share.advancedAccessLevels.timeline.permission.read
    ) {
      return false;
    }

    return true;
  }

  isSitePlanEnabled(_workspaceId: string): boolean {
    const share = GET_SHARE(_workspaceId);

    if (
      share &&
      share.advancedAccessLevels &&
      share.advancedAccessLevels.sitePlan?.permission &&
      typeof share.advancedAccessLevels.sitePlan.permission.read !== 'undefined' &&
      !share.advancedAccessLevels.sitePlan.permission.read
    ) {
      return false;
    }

    return true;
  }

  areExportsEnabled(_workspaceId: string): boolean {
    const share = GET_SHARE(_workspaceId);

    if (
      share &&
      share.advancedAccessLevels &&
      share.advancedAccessLevels.exports?.permission &&
      typeof share.advancedAccessLevels.exports.permission.read !== 'undefined' &&
      !share.advancedAccessLevels.exports.permission.read
    ) {
      return false;
    }

    return true;
  }

  getTagPermissions(_workspaceId: string): TGranularPermission {
    const share = GET_SHARE(_workspaceId);
    const user = this.userService.getUser();

    let read = true;
    let edit = true;

    if (share) {
      const userCanEditPoint = canEditPoint(share.shareOption, user);

      if (share && userCanEditPoint) {
        edit = userCanEditPoint;
      }

      edit = userCanEditPoint;
      if (share.advancedAccessLevels && share.advancedAccessLevels.tags) {
        if (
          typeof share.advancedAccessLevels.tags.permission.read !== 'undefined' &&
          !share.advancedAccessLevels.tags.permission.read
        ) {
          read = false;
        }

        if (
          typeof share.advancedAccessLevels.tags.permission.edit !== 'undefined' &&
          !share.advancedAccessLevels.tags.permission.edit
        ) {
          edit = false;
        }
      }
    }

    return {
      read,
      edit,
    };
  }

  getTagLimited(_workspaceId: string): boolean {
    const share = GET_SHARE(_workspaceId);

    let limited = false;

    if (share) {
      limited = share.tagLimited;
    }

    return limited;
  }

  checkWorkspaceCustomFieldPermissions(_workspaceId: string): {
    [customFieldId: string]: {
      read: boolean;
      edit: boolean;
    };
  } {
    const customFields = {};
    const share = GET_SHARE(_workspaceId);
    const user = this.userService.getUser();

    if (share && share.advancedAccessLevels && share.advancedAccessLevels.customFields) {
      const userCanEditPoint = canEditPoint(share.shareOption, user);

      share.advancedAccessLevels.customFields.forEach((customField) => {
        if (!customField.permission.read) {
          customFields[customField.templateId] = {
            read: false,
          };
        } else {
          customFields[customField.templateId] = {
            read: true,
          };
        }

        if (!customField.permission.edit) {
          customFields[customField.templateId].edit = false;
        } else {
          customFields[customField.templateId].edit = userCanEditPoint;
        }
      });
    }

    return customFields;
  }

  getCommentPermissions(_workspaceId: string): TGranularPermission {
    const share = GET_SHARE(_workspaceId);

    const commentPermissions = {
      read: true,
      edit: true,
    };

    if (share && share.advancedAccessLevels && share.advancedAccessLevels.timeline) {
      if (typeof share.advancedAccessLevels.timeline.comments.permission.read !== 'undefined') {
        commentPermissions.read = share.advancedAccessLevels.timeline.comments.permission.read;
      }

      if (typeof share.advancedAccessLevels.timeline.comments.permission.edit !== 'undefined') {
        commentPermissions.edit = share.advancedAccessLevels.timeline.comments.permission.edit;
      }
    }

    return commentPermissions;
  }
}
