import { cloneDeep } from 'lodash';
import isEqual from 'lodash/isEqual';

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of, timer } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';

import { TAllCustomFields, TCustomField } from '../../../custom-fields/custom-fields.model';
import { UpdatePointAssignees } from '../../points.actions';
import { TPoint, TPointsByWorkspace } from '../../points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { PermissionsService } from '../../../share/permissions.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointsService } from '../../points.service';
import { PointActivityService } from '../point-timeline/point-activity.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { canEditPoint } from 'src/app/project/modules/share/share-utils/share-permissions';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';

@Component({
  selector: 'pp-point-fields',
  templateUrl: './point-fields.component.html',
  styleUrls: ['./point-fields.component.scss'],
})
export class PointFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppPointId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppTags: string[];
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppAssignees: string[];

  pointCustomFields: {
    [id: number]: TPointCustomField;
  };

  private readonly destroy$ = new Subject<void>();

  customFields: TAllCustomFields = this.customFieldsService.getCustomFields();

  private workspaces$: Observable<TWorkspacesById>;
  private offline$: Observable<boolean>;
  private offline = false;
  workspaces: TWorkspacesById;
  ECustomFieldType = ECustomFieldType;
  EIconPath = EIconPath;

  private points$: Observable<TPointsByWorkspace>;
  point: TPoint;

  private savedAssignees: string[] = [];

  editableCustomFields: string[] = [];
  readableCustomFields: string[] = [];
  allFieldsEditable = false;
  private user: TUser;

  private cancelShowSuccessPrompt$ = new Subject<void>();
  private showSuccessPromptTimerMs = 500;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      points: TPointsByWorkspace;
      offline: boolean;
    }>,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private customFieldsService: CustomFieldsService,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private pointsUpdateService: PointsUpdateService,
    private permissionsService: PermissionsService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
    private pointsService: PointsService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.points$ = this.store.pipe(select(EStore.POINTS));
  }

  ngOnInit() {
    this.user = this.userService.getUser();

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((points) => {
      if (Object.keys(points).length > 0) {
        if (this.ppWorkspaceId && points[this.ppWorkspaceId]) {
          const point = points[this.ppWorkspaceId].entities.find(
            (searchedPoint) => searchedPoint._id === this.ppPointId,
          );

          this.point = cloneDeep(point);
          this.pointCustomFields = point?.customFieldsMap;
        }
      }
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;

      if (this.offline) {
        this.allFieldsEditable = false;
        this.editableCustomFields = [];
      } else {
        this.checkForEditableFields(this.ppWorkspaceId);
      }
    });
  }

  ngOnChanges() {
    this.savedAssignees = this.ppAssignees;

    if (this.ppWorkspaceId) {
      this.point = this.pointsService.findPoint(this.ppPointId);

      this.pointCustomFields = this.point.customFieldsMap;
    }

    this.checkForEditableFields(this.point.workspaceRef.id);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkForEditableFields(workspaceId: string): void {
    const workspace = this.workspaceService.findWorkspace(workspaceId);
    const shareOption = workspace?.share.shareOption ? workspace.share.shareOption : null;

    // Check if workspace exists in case user gets logged out (sentry.io/organizations/pinpoint-works/issues/3604901740)
    const canEdit = canEditPoint(shareOption, this.user);

    this.readableCustomFields = [];
    this.editableCustomFields = [];

    if (workspace) {
      if (
        workspace.customFields &&
        workspace.share.advancedAccessLevels &&
        workspace.share.advancedAccessLevels.customFields
      ) {
        const existingFields = this.customFieldsService.getCustomFields();
        const customFieldsPermissions =
          this.permissionsService.checkWorkspaceCustomFieldPermissions(workspace.workspaceId);

        this.allFieldsEditable = false;

        if (existingFields?.[workspace.workspaceId]) {
          workspace.customFields.forEach((customFieldId) => {
            const customField = existingFields[workspace.workspaceId][customFieldId];

            if (customField.display) {
              this.setEditableField(customFieldsPermissions, customFieldId, customField, canEdit);

              this.setReadableField(customField);
            }
          });
        }
      } else {
        this.allFieldsEditable = canEdit;
      }
    }
  }

  private setReadableField(customField: TCustomField): void {
    if (!customField.display) {
      return;
    }

    this.readableCustomFields.push(customField.id.toString());
  }

  private setEditableField(
    customFieldsPermissions: { [customFieldId: string]: { read: boolean; edit: boolean } },
    customFieldId: string,
    customField: TCustomField,
    canEdit: boolean,
  ): void {
    if (customField.lockedValue) {
      return;
    }

    if (customFieldsPermissions[customFieldId]?.edit) {
      this.editableCustomFields.push(customField.id.toString());
    } else if (canEdit && !customFieldsPermissions[customFieldId]) {
      this.editableCustomFields.push(customField.id.toString());
    }
  }

  updateAssignees(assignees: string[]): void {
    const _id = this.ppPointId;

    if (this.ppNew) {
      this.updateAssigneesForNewPoint(this.ppPointId, assignees);

      return;
    }

    if (isEqual(this.savedAssignees, assignees)) {
      return;
    }

    this.pointsUpdateService
      .updatePointField(_id, { assignees })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const prompt = this.translationPipe.transform('prompt_assignees_updated_success');

          this.savedAssignees = response.assignees;
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);
          this.cancelShowSuccessPrompt$.next();
          this.sitePointFilterService.filterPoints(true);

          timer(this.showSuccessPromptTimerMs)
            .pipe(
              takeUntil(this.cancelShowSuccessPrompt$),
              tap(() => {
                this.promptService.showSuccess(prompt);
              }),
            )
            .subscribe();
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          }

          this.promptService.showError(promptText);

          return of();
        }),
      )
      .subscribe();
  }

  private updateAssigneesForNewPoint(pointId: string, assignees: string[]): void {
    this.store.dispatch(
      new UpdatePointAssignees({
        workspaceId: this.ppWorkspaceId,
        _id: pointId,
        assignees,
      }),
    );
  }
}
