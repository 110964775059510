import { createElement } from 'src/app/core/helpers/dom';
import { EBasicField, EBasicFieldShort } from '../../../../../../shared/enums/basic-fields-enums';
import { TColumn } from '../../../columns/column.model';
import { createHeaderCustomFieldCellInnerElement } from './create-header-custom-field-cell-inner-element';
import { createHeaderStatusCellInnerElement } from './create-header-status-cell-inner-element';
import { createHeaderPriorityCellInnerElement } from './create-header-priority-cell-inner-element';
import { createHeaderSequenceNumberCellInnerElement } from './create-header-sequence-number-cell-inner-element';
import { createHeaderFlaggedCellInnerElement } from './create-header-flagged-cell-inner-element';

export function createHeaderCellInnerElement(
  _child: HTMLElement | string,
  _column: TColumn,
): HTMLElement {
  if (_child === EBasicField.PRIORITY) {
    return createHeaderPriorityCellInnerElement(_column);
  } else if (_child === EBasicField.STATUS) {
    return createHeaderStatusCellInnerElement(_column);
  } else if (_child === 'Flag') {
    return createHeaderFlaggedCellInnerElement(_column);
  } else if (_child === EBasicFieldShort.SEQUENCE_NUMBER) {
    return createHeaderSequenceNumberCellInnerElement(_column);
  } else if (_column) {
    return createHeaderCustomFieldCellInnerElement(_child, _column);
  } else {
    const cellInnerElement = createElement<HTMLElement>('div', {
      attrs: {
        class: 'table__cell__inner table__head__cell',
      },
      children: [
        createElement<HTMLElement>('span', {
          children: [_child],
        }),
      ],
    });

    return cellInnerElement;
  }
}
