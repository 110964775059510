import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';

import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { PointsUpdateService } from '../points-update.service';
import { PointsService } from '../points.service';

@Component({
  selector: 'pp-point-bulk-flag-modal',
  templateUrl: './point-flag-modal.component.html',
  styleUrls: ['./point-flag-modal.component.scss'],
})
export class PointBulkFlagModalComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  workspace = this.workspaceService.findWorkspace(this.activeService.getActiveWorkspaceId());

  processing = false;
  selectedFlag = false;
  EIconPath = EIconPath;

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private activeService: ActiveService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnInit() {
    this.selectedFlag = this.pointsService.getSelectedPoints()[0].flagged;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  save(): void {
    this.processing = true;
    const selectedPoints = this.pointsService.getSelectedPoints();
    const pointIds = selectedPoints.map((point) => point._id);
    const numberOfSelectedPoints = selectedPoints.length;

    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__EDIT, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'FLAGGED',
      event_details: `${numberOfSelectedPoints}`,
    });

    this.pointsUpdateService
      .updatePointsFlag(pointIds, this.selectedFlag)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.handleUpdatePointSuccess();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_points_update_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  toggleFlag(flag: boolean): void {
    this.selectedFlag = flag;
  }

  private handleUpdatePointSuccess() {
    const promptText = this.translationPipe.transform('prompt_points_update_success');

    this.promptService.showSuccess(promptText);
    this.sitePointFilterService.filterPoints(true);
    this.hideModal();
  }
}
