export enum EVolyIntegrationStatus {
  OFF = 'OFF',
  PENDING = 'PENDING',
  ON = 'ON',
}

export type TWorkspaceIntegrationData = {
  id: string;
  name: string;
};

export type TAuditLog = {
  integrationActivityType: EIntegrationActivityType;
  timestampEpochMillis: number;
  workspaceId: string;
  userEmail?: string;
  assetName?: string;
};

export enum EIntegrationActivityType {
  PENDING = 'PENDING', // <user email> made the site available for integration. Pending status valid until <date time one week from now>
  PENDING_TIMEOUT = 'PENDING_TIMEOUT', // No integration connection made after 7 days, site no longer available for integration.
  CANCEL = 'CANCEL', // <user email> cancelled the authorisation for integration prior to completion, site no longer available for integration.
  CONNECT = 'CONNECT', // Site connected to Voly asset <asset name>. Integration active.
  DISCONNECT_PW = 'DISCONNECT_PW', // <user email> has disconnected the integration.
  DISCONNECT_VOLY = 'DISCONNECT_VOLY', // Integration disconnected from within Voly Projects.
}
