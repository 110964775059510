import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { checkFieldVolyTooltip } from '../../../../site-options/utils/check-field-voly-tooltip';
import { TColumn } from '../../../columns/column.model';
import { createHeaderTooltip } from './create-header-tooltip';
import { createSortingIndicatorElement } from './create-sorting-indicator-element';

export function createHeaderCustomFieldCellInnerElement(
  child: HTMLElement | string,
  column: TColumn,
): HTMLElement {
  const nameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: column?.sortOrder
        ? 'site-table__sum__title--sorted site-table__sum__title'
        : 'site-table__sum__title',
      id: 'sortingElement',
    },
    children: [child],
  });

  const integrationNameElement = createIntegrationImage(column);

  const sortingElement = column?.sortOrder
    ? createSortingIndicatorElement(column.sortIndex, column.sortOrder)
    : '';

  const cellNameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'table__head__cell--inner',
    },
    children: [nameElement, sortingElement],
  });

  let classList = getElementClassList(integrationNameElement);

  const cellInnerElement = createElement<HTMLElement>('div', {
    attrs: {
      class: classList,
    },
    children: [integrationNameElement, cellNameElement],
  });

  createHeaderTooltip(column.name, cellNameElement);

  return cellInnerElement;
}

function getElementClassList(integrationNameElement: string | HTMLElement): string {
  let classList =
    'table__cell__inner__namedColumn table__cell__inner table__cell__inner--noPadding table__head__cell';

  if (integrationNameElement) {
    classList += ' table__cell__inner__namedColumn--integration';
  }
  return classList;
}

function createIntegrationImage(column: TColumn): HTMLElement | string {
  if (!column.customFieldIds?.length) {
    return '';
  }

  let element: HTMLElement;

  const customFieldId = column.customFieldIds[0];
  const customFields = GET_CUSTOM_FIELDS();
  const volyIntegrationActive = customFields[customFieldId]?.volyIntegrationActive;

  switch (volyIntegrationActive) {
    case EIntegrationStatus.ON:
      element = createElement('img', {
        attrs: {
          src: EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_BIG,
        },
      });
      break;
    case EIntegrationStatus.DISABLED:
      element = createElement('img', {
        attrs: {
          src: EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_BIG,
        },
      });
      break;
    default:
      break;
  }

  if (element) {
    const tooltipText = checkFieldVolyTooltip(column);

    const tooltip = new Tooltip({
      options: {},
      title: tooltipText,
      mobileTooltip: false,
      trimTooltip: false,
      childTooltip: false,
      element: element,
    });

    tooltip.createTooltip();

    return element;
  } else {
    return '';
  }
}
